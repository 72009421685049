import { type FC } from 'react'
import { CircleChecked, Page } from '@lib/components'
import { Header, WithFooter } from '~/components'
import { Link } from 'react-router-dom'

export const PrivacyPolicy: FC = () => {
  return <WithFooter>
    <Header />
    <Page skinny className="px-10 my-16 font-medium">
      <section>
        <h1 className="my-8 text-2xl font-semibold md:text-4xl text-mtn-blue-800">Privacy Policy</h1>
        <p className="mt-4">“My Ten Nights” is the trading name of Shamaazi Limited (“we”, “us” or “Shamaazi”). We take the privacy of your information very seriously. This Privacy
          Notice is designed to tell you about our practices regarding the collection, use and disclosure of personal information which may be collected in person from you,
          obtained via our websites or apps, or collected through other means such as by an online form or email communication.</p>
        <p className="mt-4">This notice applies to the charitable donation and related services we provide (the “Service”) and relates to any personal information provided by our
          users, customers and contacts (including donors and other users or guests on our sites or apps) whose data we process.</p>
        <p className="mt-4">In this notice “you” refers to any individual whose personal data we hold or process. This notice is governed by the EU General Data Protection
          Regulation and UK GDPR (collectively referred to as the “GDPR”).</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section>
        <h2 className="my-4 text-xl md:text-2xl text-mtn-blue-800">Personal data we collect and how we process this data</h2>
        <p className="mt-4">Below we have set out the categories of data we collect, the legal basis we rely on to process the data and how we process the data:</p>

        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Contact information for donors who donate through the Service, as well as any individuals who sign up to our mailing list (or ask or agree to receive information
              relating to our products and services) and our users generally who may contact us. This information may include names, email addresses, postal addresses, and product
              or service preferences (“<strong>Contact Information”</strong>). We process this information when we communicate with you or provide services to you on the basis of
              the performance of our contract with you, or if we have no ongoing contract with you on the basis of our legitimate interest in providing and operating the Service.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Information relating to any donations made through the Service (“<strong>Donation Information</strong>”) which may include value of donations, dates and times of
              donations, the charities or organisations in receipt of donations and records of total donations. We process this information when we process and manage donations on
              the basis of the performance of our contract with you, or if we have no ongoing contract with you on the basis of our legitimate interest in providing and operating
              the Service.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Information provided in relation to comments or reviews posted to the Service (“<strong>Comment Information</strong>”). We process this information on the basis of
              our legitimate interest in providing the Service and displaying such comments or reviews.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Information provided relating to payment for your donation such as credit or debit card information (“<strong>Payment Information</strong>”) and please note that
              Payment Information may be supplied directly to a third party payment processor who will process the data in accordance with their own privacy notices or policies
              (see below). We process this information when we manage donations or collect or process payments on the basis of the performance of our contract with you in providing
              the Service.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              A record of any correspondence or communication between you and us (“<strong>Communication Information</strong>”). We process this information when we monitor our
              relationship with you and provide services to you on the basis of the performance of our contract with you, or if we have no ongoing contract with you on the basis of
              our legitimate interest in providing the Service and communicating with our users.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Marketing information we may hold about you in order to provide information about the Service which may include names, email addresses, addresses, and other
              information regarding your preferences and otherwise (“<strong>Marketing Information</strong>”). We process this information in order to communicate with you about
              our products and services on the basis of our legitimate interests in communicating with you about our services or on the basis that you have consented to receive
              such information.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              We will collect the above information from you directly but there may be occasions when we collect information from a third party. If we do obtain your personal data
              from a third party your privacy rights under this notice are not affected and you are still able to exercise the rights contained within this notice.
            </div>
          </li>
        </ul>

        <p className="mt-4">This Privacy Notice also applies to personal data provided by our partner charities (which may include the organisation, firm or entity for whom you
          work) and their staff, and any third party suppliers whose data we process. When we process personal data of this nature we do so on the basis of the performance of our
          contract with your employer or engaging organisation, or if we have no such ongoing contract on the basis of our legitimate interest in providing and operating the
          Service.</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section>
        <h2 className="my-4 text-xl md:text-2xl text-mtn-blue-800">Data retention</h2>
        <p className="mt-4">Our current data retention policy is to delete or destroy (to the extent we are able to) personal data after the following periods:</p>

        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Records relating to a contract with us - 7 years from either the end of the contract or the date you last used the Service, being the length of time following a
              breach of contract in which a contract party is entitled to make a legal claim.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Marketing records - 3 years from the last date on which you have interacted with us.
            </div>
          </li>
        </ul>

        <p className="mt-4">For any category of personal data not specifically defined in this notice, and unless otherwise specified by applicable law, the required retention
          period for any personal data will be deemed to be 7 years from the date of receipt by us of that data. The retention periods stated in this notice can be prolonged or
          shortened as may be required (for example, in the event that legal proceedings apply to the data or if there is an on-going investigation into the data).</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section>
        <h2 className="my-4 text-xl md:text-2xl text-mtn-blue-800">Cookies Policy</h2>
        <p className="mt-4">Like most digital services, we use cookies to help provide you with the best experience whilst using our service. The cookies we use are split between
          the following categories:</p>

        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Strictly Necessary cookies - which are an essential part of our service and affect the way you can use our site (e.g. security &#38; authentication).
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Performance cookies - which are used for analytics (e.g. understanding usage on our website).
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Functionality cookies - which collect information about your device to help you customize our service (e.g. remembering your timezone settings or accessing inline
              help).
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Targeting cookies - these cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make
              our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
            </div>
          </li>
        </ul>
        <p className="mt-4">On your first visit to our site from your browser we will display a notice to notify you that we are using cookies. We will only load the Strictly
          Necessary cookies and Functionality cookies until you have clicked the “Accept” button on our cookies notice. If you click the “Accept” button our Performance cookies and
          Targeting cookies will be loaded.</p>
        <p className="mt-4">Below is a list of the cookies currently set by us:</p>
        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mr-4 text-mtn-blue" />
            <div className="flex-1">
              Authentication cookies - Strictly Necessary - Used for your security and convenience
            </div>
          </li>
        </ul>

        <h3 className="mt-4 text-mtn-blue-800">Third-party cookies</h3>
        <p className="mt-4">As well as cookies that are set by domains we control (first-party cookies), you may also see cookies set by a third party (third-party cookies). These
          are set when you interact with certain parts of our service and are used by these third-party services to understand your preferences and sometimes tailor content they
          show you.</p>
        <p className="mt-4">These third party cookies are likely to be analytical cookies or performance cookies or targeting cookies:</p>
        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mr-4 text-mtn-blue" />
            <div className="flex-1">
              Facebook
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mr-4 text-mtn-blue" />
            <div className="flex-1">
              Google
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mr-4 text-mtn-blue" />
            <div className="flex-1">
              Stripe
            </div>
          </li>
        </ul>
        <p className="mt-4">To deactivate the use of third party advertising cookies, please choose your preferences by clicking <Link to="/cookie-preferences"
                                                                                                                                       className="underline text-mtn-blue-800">here.</Link>
        </p>

        <h3 className="mt-4 text-mtn-blue-800">Refusing cookies</h3>
        <p className="mt-4">You can opt out of cookies on our site at any time by adjusting your preferences <Link className="underline text-mtn-blue-800"
                                                                                                                   to="cookie-preferences">here</Link>.</p>
        <p className="mt-4">If you would like to learn more about interest-based advertising and see what choices you have about how companies use your information please go to <a
          className="underline text-mtn-blue-800" href="https://www.youronlinechoices.com/" target="_blank" rel="noopener noreferrer">youronlinechoices.com</a> or <a
          className="underline text-mtn-blue-800" href="https://aboutads.info" target="_blank" rel="noopener noreferrer">aboutads.info</a>.</p>
        <p className="mt-4">The majority of browsers are set up to accept cookies by default but you can change the way your browser handles cookies if you wish. You could reject
          them by default or be notified when a website is trying to set or update cookie preferences. Exactly how you disable cookies depends on the browser or device you are
          using. The help feature on most browsers will tell you how you how you can manage and or disable cookies. If you disable cookies on your browser, certain features or
          parts of the Service may not function correctly or will have a degraded experience.</p>

        <h3 className="mt-4 text-mtn-blue-800">Targeting and advertising</h3>
        <p className="mt-4">We use Facebook Pixel to deliver targeted advertisements to individuals who visit our website. Using cookies, we track your activity on our site and
          share it with Facebook to analyse our website's conversion flows and optimise our ad campaigns. The collected data is anonymous meaning we can't see your personal data,
          but it is processed and stored by Facebook. Facebook is able to connect the data with your Facebook account and use the data for their own advertising purposes. You can
          view Facebook's privacy policy <a className="underline text-mtn-blue-800" href="https://www.facebook.com/about/privacy" target="_blank"
                                            rel="noopener noreferrer">here</a> and manage your Facebook ad preferences <a className="underline text-mtn-blue-800"
                                                                                                                          href="https://www.facebook.com/ads/website_custom_audiences/"
                                                                                                                          target="_blank" rel="noopener noreferrer">here</a>.</p>
        <p className="mt-4">We also use the Remarketing feature from Google Ads to reach out to people who have previously visited our site. We and Google use cookies to inform,
          optimise and serve ads based on your past use of our service. These cookies allow Google to identify what you find popular and offer a more personalised selection of
          advertisements. You can opt out of targeted Google ads by adjusting your settings <a className="underline text-mtn-blue-800" href="https://www.google.com/settings/ads"
                                                                                               target="_blank" rel="noopener noreferrer">here</a>.</p>

        <h3 className="mt-4 text-mtn-blue-800">MTNtracking and analytics cookies</h3>
        <p className="mt-4">We use analytic tools, such as Google analytics, to understand how our site is used and where we can make improvements to provide you with the best
          experience. You can opt out of this analytics tracking by not clicking the “Accept” button or by using Google’s opt out tool.</p>
        <p className="mt-4">If you choose not to accept the cookies, this will not affect your access to the majority of information available on the Service. However, you will not
          be able to make full use of our online services.</p>

        <h3 className="mt-4 text-mtn-blue-800">Duration</h3>
        <p className="mt-4">Except for essential cookies, all cookies will expire after 2 years.</p>

        <h3 className="mt-4 text-mtn-blue-800">IP Addresses</h3>
        <p className="mt-4">An Internet Protocol (IP) address is a number assigned to your computer by your Internet Service Provider (ISP), so you can access the Internet. We may
          use your IP address to diagnose problems with our server, report aggregate information, and determine the fastest route for your computer to use in connecting to our
          service, and to administer and improve our service.</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section>
        <h2 className="my-4 text-xl md:text-2xl text-mtn-blue-800">Sharing your information</h2>
        <p className="mt-4">We may disclose information to third parties in the following circumstances:</p>

        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              In operating the Service we may provide information to our third party service providers, including payment processors and suppliers who assist with email
              distribution, IT services and customer services.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Your information may be shared with the charities or organisations to whom you have donated or expressed an interest in through the Service. Subject to satisfactory
              due diligence, we may agree that charity partners are controllers of your data in common with us, as opposed to processors subject to a separate data processing
              agreement.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              This allows the recipient entity in question to, among other matters, verify and process your donations, claim Gift Aid from HMRC, and (subject to your selected
              marketing preferences) make contact with you.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Your name will not be provided unless you provide appropriate permissions.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              If you consent to being contacted by the recipient charity or organisation you will need to contact that entity directly if you wish to raise any queries or concerns
              regarding your personal data or to exercise your privacy rights in relation to the data they hold and process about you, including if you wish to revoke your consent
              to their contacting you and processing your data.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              We may target adverts to donors and measure how they perform, both through our own services and on our own websites and apps, and through other platforms such as
              social media sites and other websites, and we may share your personal data with third parties for this purpose. We may also share your personal data with third
              parties, such as social media platforms, to allow that third party to understand the type of person who uses our services and create lookalike audiences for
              advertising purposes.
            </div>
          </li>
        </ul>

        <p className="mt-4">We may disclose information to our group companies for internal operational reasons.</p>
        <p className="mt-4">If we are under a duty to disclose or share your personal data in order to comply with any legal obligation (for example, if required to do so by a
          court order or for the purposes of prevention of fraud or other crime).</p>
        <p className="mt-4">In order to enforce any terms and conditions or agreements for our services that may apply.</p>
        <p className="mt-4">We may transfer your personal information to a third party as part of a sale of some or all of our business and assets to any third party or as part of
          any business restructuring or reorganisation, or during the course of third party investment into our business, but we will take steps with the aim of ensuring that your
          privacy rights continue to be protected.</p>
        <p className="mt-4">To protect our rights, property and safety, or the rights, property and safety of our users or any other third parties. This includes exchanging
          information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</p>
        <p className="mt-4">If we do supply your personal information to a third party we will take steps to ensure that your privacy rights are protected and that third party
          complies with the terms of this notice.</p>
        <p className="mt-4">As a default we rely on our legitimate interests as the legal basis for sharing personal data in these ways but this is always subject to the terms of
          your consents or your expressions of interest or preference (if any).</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section>
        <h2 className="my-4 text-xl md:text-2xl text-mtn-blue-800">Security</h2>
        <p className="mt-4">We will take all reasonable steps to ensure that appropriate technical and organisational measures are carried out in order to safeguard the information
          we collect from you and protect against unlawful access and accidental loss or damage.</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section>
        <h2 className="my-4 text-xl md:text-2xl text-mtn-blue-800">Your privacy rights</h2>
        <p className="mt-4">With respect to your personal data, you have the right to:</p>

        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              request that your personal data will not be processed.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              ask for a copy of any personal data that we have about you.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              request a correction of any errors in or update of the personal data that we have about you.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              request that your personal data will not be used to contact you for direct marketing purposes.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              request that your personal data will not be used for profiling purposes.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              request that your personal data will not be used to contact you at all.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              request that your personal data be transferred or exported to another organisation, or deleted from our records.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              at any time, withdraw any permission you have given us to process your personal data.
            </div>
          </li>
        </ul>

        <p className="mt-4">All requests or notifications in respect of your above rights may be sent to us in writing at the contact details listed below. We will endeavour to
          comply with such requests as soon as possible but in any event we will comply within one month of receipt (unless a longer period of time to respond is reasonable by
          virtue of the complexity or number of your requests).</p>
        <p className="mt-4">If personal data we hold about you is subject to a breach or unauthorised disclosure or access, we will report this to the Information Commissioner’s
          Office (ICO) and/or our data protection manager.</p>
        <p className="mt-4">If a breach is likely to result in a risk to your data rights and freedoms, we will notify you as soon as possible.</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section>
        <h2 className="my-4 text-xl md:text-2xl text-mtn-blue-800">Transferring your information outside Europe</h2>
        <p className="mt-4">We will not transfer your personal data in a systematic way outside of the European Economic Area or UK (“EEA”) but there may be circumstances in which
          certain personal information is transferred outside of the EEA, in particular:</p>

        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              If you are based outside of the EEA or use our services while you are outside the EEA, your information may be transferred outside the EEA in order to provide you
              with our services.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              We may communicate with individuals or organisations outside of the EEA in providing our services and those communications may include personal information (such as
              contact information), for example you may be outside of the EEA when we communicate with you.
            </div>
          </li>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              From time to time your information may be stored in devices which are used by our staff outside of the EEA (but staff will be subject to our cyber-security policies).
            </div>
          </li>
        </ul>

        <p className="mt-4">If we transfer your information outside of the EEA, and the third country or international organisation in question has not been deemed by the EU
          Commission to have adequate data protection laws, we will provide appropriate safeguards and we will be responsible for ensuring your privacy rights continue to be
          protected as outlined in this notice.</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section>
        <h2 className="my-4 text-xl md:text-2xl text-mtn-blue-800">Contact us</h2>
        <p className="mt-4">If at any time you would like to contact us with your views about our privacy practices, or with any enquiry or complaint relating to your personal
          information or how it is handled, you can do so by us using the details below.</p>
        <div className="sm:flex">
          <div className="sm:flex-1">
            <p className="mt-4 underline text-mtn-blue-800">Address</p>
            <p>6-8 Great Eastern Street,<br />
              London,<br />
              EC2A 3NT,<br />
              United Kingdom
            </p>
          </div>
          <div className="sm:flex-1">
            <p className="mt-4 underline text-mtn-blue-800">Email</p>
            <a href="mailto:contact@shamaazi.com">contact@shamaazi.com</a>
          </div>
        </div>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section>
        <h2 className="my-4 text-xl md:text-2xl text-mtn-blue-800">Complaints</h2>
        <p className="mt-4">If we are unable to resolve any issues you may have or you would like to make a further complaint, you can contact the Information Commissioner’s Office
          by visiting <a className="underline" href="https://www.ico.org.uk/">www.ico.org.uk/</a> for further assistance.</p>
      </section>

    </Page>
  </WithFooter>
}
