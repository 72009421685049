/* eslint-disable */
// @ts-nocheck
import { isLive } from '@shamaazi/mytennights'

export const gaSnippet = () => {
  (function (i, s, o, g, r, a, m) {
    i.GoogleAnalyticsObject = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')

  ga('create', isLive ? 'UA-189645571-2' : 'UA-241949437-1', 'auto')
  ga('require', 'ecommerce')
  ga('send', 'pageview')
}

export const gaRetargeting = () => {
  const newElement = document?.createElement('script')
  newElement.async = 1
  newElement.src = 'https://www.googletagmanager.com/gtag/js?id=AW-388261978'
  const element = document?.getElementsByTagName('script')[0]
  element.parentNode?.insertBefore(newElement, element)

  window.dataLayer = window.dataLayer || []

  function gtag () { dataLayer.push(arguments) }

  gtag('js', new Date())
  gtag('config', 'AW-388261978')
}
/* eslint-enable */
