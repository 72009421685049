import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { type FC } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ErrorBoundary, PageViewEvents, ScrollToTop, Spinner } from '@lib/components'
import { ProvideAuth } from '@lib/hooks'
import { FlowRedirect, ShareRedirect } from '~/components/CheckoutRedirects'
import { AuthenticatedRoute } from '~/components/Login'
import { AddressLookupStore, CheckoutPlanProvider, defaultVariants, PaymentContainer, MTNtracking, isLive, TestVersion, ActiveExperiments } from '@shamaazi/mytennights'
import { Experiment, experimentDebugger, Variant } from '@lib/react-ab-test'
import {
  About,
  Causes,
  CharityLanding,
  Contact,
  CookiePreferences,
  Details,
  ErrorPage,
  FAQ,
  MarketingPreferences,
  NotFound,
  PrivacyPolicy,
  ScheduleNight,
  SelectCharity,
  TermsAndConditions
} from '~/pages'
import Share from './pages/Share'
import ShareOptions from './pages/ShareOptions'
import { CookieBanner, Navigation } from './components'
import { browserExceptions, SplittestingStore } from '@lib/services'

import { configure } from 'mobx'
import { observer } from 'mobx-react'
import { Home } from '~/pages/home'
import { Payment } from '~/pages/payment'
import { Pages } from '~/components'
import { ScheduleList } from '~/pages/profile/ScheduleList'
import { Schedule } from '~/pages/profile/Schedule'
import { FGScheduleList } from './pages/profile/FGScheduleList'
import { FGScheduleDetail } from './pages/profile/FGScheduleDetail'
import { Fees } from './pages/Fees'

if (!isLive) {
  experimentDebugger.enable()
}

configure({
  enforceActions: 'never'
})

void browserExceptions.initialize()
MTNtracking.initialize()

const queryClient = new QueryClient()

interface AppProps {
  readonly splittestingStore: SplittestingStore
}

const addressLookupStore = new AddressLookupStore()

const splitTestingStore = new SplittestingStore(defaultVariants)
void splitTestingStore.load()

const App: FC<AppProps> = observer(({ splittestingStore }) => {
  return <>
    {splittestingStore.isLoading &&
      <div className="flex justify-center mt-20"><Spinner className="text-mtn-blue" /></div>}
    {!splittestingStore.isLoading && !splittestingStore.error &&
      <ErrorBoundary component={<ErrorPage />}>
        <ProvideAuth campaignQueryParameter={''}>
          <QueryClientProvider client={queryClient}>
            <CheckoutPlanProvider>
              <Router>
                <PageViewEvents tracking={MTNtracking} trackable={MTNtracking.isTrackableUrl}/>
                <ScrollToTop />
                <Switch>
                  <Route exact path={Navigation.home}>
                    <Home nextPage={Navigation.charitySelection()} />
                  </Route>
                  <Route exact path={Navigation.partnerLanding()}>
                    <Home nextPage={Navigation.charitySelection()} />
                  </Route>

                  {/* Allow partner prefix in the route to allow opening MTN site outside in-app browser which can redirect to partner landing page rather than the MTN homepage */}
                  <Route exact path={[Navigation.charitySelection(), Navigation.partnerCharitySelection()]}>
                    <FlowRedirect page={Pages.Charity}>
                      {/* The below experiment is added here so that the experiment is passed in the mixpanel event on page load. Exact logic handled in the HomePageCharities component */}
                      <Experiment name={ActiveExperiments.CharityPennyAppealUK}>
                        <Variant name={TestVersion.control}>
                          <SelectCharity nextPage={Navigation.charityCauses()} />
                        </Variant>
                        <Variant name={TestVersion.variant}>
                          <SelectCharity nextPage={Navigation.charityCauses()} />
                        </Variant>
                      </Experiment>
                    </FlowRedirect>
                  </Route>
                  <Route exact path={[Navigation.charityCauses(), Navigation.partnerCharityCauses()]}>
                    <FlowRedirect page={Pages.Causes}>
                      <Causes nextPage={Navigation.details()} />
                    </FlowRedirect>
                  </Route>
                  <Route exact path={[Navigation.details(), Navigation.partnerDetails()]}>
                    <FlowRedirect page={Pages.Details}>
                      <Details nextPage={Navigation.payment()} />
                    </FlowRedirect>
                  </Route>
                  <Route exact path={[Navigation.payment(), Navigation.partnerPayment()]}>
                    <FlowRedirect page={Pages.Payment}>
                      <PaymentContainer campaign="MyTenNights">
                        <Payment nextPage="/share" addressLookupStore={addressLookupStore} />
                      </PaymentContainer>
                    </FlowRedirect>
                  </Route>
                  <Route exact path="/share">
                    <ShareRedirect>
                      <Share />
                    </ShareRedirect>
                  </Route>
                  <Route exact path="/about-us">
                    <About />
                  </Route>
                  <Route exact path="/terms-and-conditions">
                    <TermsAndConditions />
                  </Route>
                  <Route exact path="/privacy-policy">
                    <PrivacyPolicy />
                  </Route>
                  <Route exact path="/cookie-preferences">
                    <CookiePreferences />
                  </Route>
                  <Route exact path="/faq">
                    <FAQ />
                  </Route>
                  <Route exact path="/fees">
                    <Fees />
                  </Route>
                  <Route exact path="/contact">
                    <Contact />
                  </Route>
                  <Route exact path="/share/:shareCode">
                    <ShareOptions />
                  </Route>
                  <Route exact path="/login">
                    <Redirect to="/account/schedules" />
                  </Route>
                  <Route exact path="/:charityID">
                    <CharityLanding nextPage={Navigation.charityCauses()} />
                  </Route>
                  <Route exact path="/link/:chainCode">
                    <Home nextPage={Navigation.charitySelection()} />
                  </Route>
                  <AuthenticatedRoute exact path="/account/schedules">
                    <ScheduleList />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path="/account/fridayGiving">
                    <FGScheduleList/>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path="/account/preferences">
                    <MarketingPreferences />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path="/account/schedules/:scheduleID">
                    <Schedule />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path="/account/fridayGiving/:fridayGivingScheduleID">
                    <FGScheduleDetail isRunPage={false} />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path="/account/schedules/:scheduleID/night/:nightNumber">
                    <ScheduleNight />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute exact path="/account/fridayGiving/:fridayGivingScheduleID/run/:paymentDate">
                    <FGScheduleDetail isRunPage={true} />
                  </AuthenticatedRoute>
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
                <CookieBanner />
              </Router>
            </CheckoutPlanProvider>
          </QueryClientProvider>
        </ProvideAuth>
      </ErrorBoundary>
    }
  </>
})

const AppContainer: FC = () => {
  return <App splittestingStore={splitTestingStore} />
}

export default AppContainer
