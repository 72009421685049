import { type FC } from 'react'

export enum Pages {
  Charity = 'Charity',
  Causes = 'Causes',
  Details = 'Details',
  Payment = 'Payment',
}

const wizardDonationSplit = [Pages.Charity, Pages.Causes, Pages.Details, Pages.Payment]

const getStepElement = (checkoutStep: Pages, progressStep: Pages, order: Pages[]): JSX.Element => {
  return <li key={progressStep} className={`flex flex-col gap-y-3 w-full justify-center items-center ${order.indexOf(checkoutStep) >= order.indexOf(progressStep) ? 'current' : ''}`}>
    <div
      className={`${order.indexOf(checkoutStep) >= order.indexOf(progressStep) ? 'bg-mtn-blue' : 'progress-bar-bg'} w-8 h-8 text-sm sm:w-8 sm:h-8 sm:text-md rounded-full flex justify-center items-center text-white`}>
      {`0${order.indexOf(progressStep) + 1}`}
    </div>
    <span className={`${order.indexOf(checkoutStep) >= order.indexOf(progressStep) ? 'text-mtn-blue' : 'progress-bar-text'} uppercase text-sm font-thin`}> {progressStep}</span>
  </li>
}

export const CheckoutProgress: FC<{ step: Pages }> = ({ step }) => {
  return <div className="flex justify-center">
    <ol className={'grid sticky top-0 z-30 grid-cols-4 py-3 w-full md:w-1/2 lg:w-1/3 items-center font-medium bg-white sm:py-4 progressBar'}>
        {Object.values(wizardDonationSplit).map((page) => getStepElement(step, page, wizardDonationSplit))}
      </ol>
  </div>
}
