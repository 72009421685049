import { type FC } from 'react'

import { Card } from '@shamaazi/mytennights'
import { CircleChecked } from '@lib/components'

import share from './givematchShareContent/share-background.jpg'
import { type ShareDetails } from '~/hooks/useSharePageDetails'
import { ShareLinks } from '~/components'

export const ShareContent: FC<{ details: ShareDetails }> = ({ details }) => {
  return <div className="flex pb-20 h-full">
    <div className="flex flex-1 p-4 bg-bottom bg-cover" style={{ backgroundImage: `url(${share})` }}>
      <Card variant="mtn" className="p-8 m-auto font-medium text-center bg-white">
        <div className="justify-center items-center sm:flex">
          <CircleChecked className="mx-auto w-10 h-10 text-mtn-blue-500 sm:mx-0" />
          <h1 className="pl-4 text-lg md:text-2xl text-mtn-blue-800">
            You&apos;re all set for Laylatul Qadr
          </h1>
        </div>
        <p className="mt-4 text-sm font-semi-bold">
          A confirmation email has been sent to {details.email}
        </p>
        <div className="my-4 border-b border-mtn-gray-300"></div>
        <ShareLinks shareCode={details.shareCode} />
      </Card>
    </div>
  </div>
}
