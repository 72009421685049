import { type FC } from 'react'
import { Header, WithFooter } from '~/components'
import { Page } from '@lib/components'
import nightSky from '~/img/night-sky.jpg'

export const About: FC = () => {
  return <WithFooter>
    <Header transparent />

    <section className="flex flex-col px-4 bg-top bg-cover min-h-600" style={{ backgroundImage: `url(${nightSky})` }}>
      <div className="flex flex-1 pt-24 mx-auto max-w-2xl text-center">
        <div className="my-auto ">
          <h1 className="my-6 text-2xl font-bold text-white md:text-4xl">
            Our mission is to help you never miss giving on Laylatul Qadr again.
          </h1>
          <div className="m-auto w-32 border border-mtn-blue-500"></div>
          <p className="my-6 font-medium text-white md:text-xl">
            We are enormously proud to have helped over 160,000 people donate on Laylatul Qadr.
          </p>
        </div>
      </div>
    </section>

    <section>
      <Page className="px-10 my-16 lg:flex">
        <div className="flex-auto font-bold text-mtn-blue-800">
          <h2 className="text-2xl">Our Values</h2>
          <h3 className="mt-8 text-xl">Tech for good</h3>
          <p className="mt-4 font-medium text-mtn-gray">We are a tech-for-good company. Technology has transformed the world we live in. We believe in harnessing this power to
            leave the world better than we found it.</p>
          <h3 className="mt-8 text-xl">Innovation</h3>
          <p className="mt-4 font-medium text-mtn-gray">We build industry-leading tech that starts with the donor in mind, then works backwards.</p>
          <h3 className="mt-8 text-xl">Accountability</h3>
          <p className="mt-4 font-medium text-mtn-gray">Your data is secure with us. We never sell or trade your personal information with third parties.</p>
        </div>

        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5 lg:bg-gradient-to-b lg:h-96 lg:my-auto lg:mx-12 lg:w-0.5"></div>

        <div className="flex-auto font-medium">
          <h2 className="text-2xl font-bold text-mtn-blue-800">Our Purpose</h2>
          <h3 className="mt-8 text-xl font-bold text-mtn-blue-800">"Laylatul Qadr is better than a thousand months" <span className="font-medium">(97:3)</span></h3>
          <p className="mt-4">Ramadan is the most blessed time of the year so we all make stronger efforts to do good and benefit from the multiplied reward, more so during the
            last 10 nights which holds the most important night of the year: Laylatul Qadr. As part of our Ramadan activities, charity-giving has an important role as we donate
            from our hearts to be rewarded all the while helping our fellow humans in need.</p>
          <p className="mt-4">Although we make massive efforts to do more good, we know that Ramadan can be quite intense. We’re living our regular daily lives with the additional
            Ramadan routine on top and so sometimes we may end up forgetting to donate, which is a huge opportunity missed - especially in the last 10 nights.</p>
          <p className="mt-4">So we created MyTenNights, a platform that lets you automate your donations across the last 10 nights, so you never miss giving on Laylatul Qadr
            again, no matter what you have on your plate. Our unique tech makes sure your donation is processed after sunset in your local time so you can always be confident about
            your nightly donations.</p>
          <p className="mt-4">Our ambition at MyTenNights is to help every Muslim all over the world to catch Laylatul Qadr, so this year insha’Allah you can catch it too!</p>
        </div>
      </Page>
    </section>
  </WithFooter>
}
