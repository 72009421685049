import { type FC } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Button, CircleChecked, CircleExclamation, Page, Spinner } from '@lib/components'
import { displayCurrency } from '@lib/services'
import { AccountTabs, Card, loadSchedules, Variant, isCampaignOpen, MTNtracking } from '@shamaazi/mytennights'

import { Header, WithFooter } from '~/components'

import { DateTime } from 'luxon'
import DonateNowButton from '~/components/DonateNowButton'
import { useAuth } from '@lib/hooks'
import { ScheduleListTestIds } from '@lib/testing'

export const ScheduleList: FC = () => {
  const { data: schedules, isLoading, isError } = useQuery('schedules', async () => loadSchedules('mtn'))
  const { user } = useAuth()

  const scheduleCards = (schedules ?? []).map((s, i) => {
    const scheduleNumber = (schedules?.length ?? 0) - i
    return <Card key={s.scheduleID} variant="mtn" className="flex flex-col font-medium bg-white" data-test-id={`${ScheduleListTestIds.schedule_}${s.givematchShareCode}`}>
      <section className="p-10 border-b border-mtn-gray-300">
        <h2 className="text-lg text-mtn-blue-800">Schedule #{scheduleNumber}</h2>
        <p className="mt-4 text-xs">
          Created at {s.created.toLocaleString(DateTime.TIME_24_SIMPLE)} on {s.created.toLocaleString(DateTime.DATE_MED)}
        </p>
        {s.clonedFromSchedule && <p className="mt-4 text-xs">
          Auto enrolled from previous campaign
        </p>}
      </section>
      <section className="p-10 border-b border-mtn-gray-300">
        <div className="grid items-center gap-y-4 gap-x-6 grid-cols-auto-fr">
          <span className="text-sm">Charity</span>
          <span className="text-mtn-blue-800">{s.charity}</span>
          <span className="text-sm">Total</span>
          <span className="text-mtn-blue-800">{displayCurrency({ amount: s.totalAmount, currency: s.currency })}</span>
        </div>
      </section>
      <section className="flex flex-col flex-1 p-10">
        <div className="flex items-center flex-1 mb-6">
          <CircleChecked className="w-8 h-8 mr-4 text-mtn-blue" />
          <span>
            {s.completedCount === 0 && s.failedCount === 0 && <>You&apos;re all set up and ready<br /></>}
            {s.completedCount} of {s.numberOfPayments} donations completed
          </span>
        </div>
        {s.failedCount > 0 && <div className="flex items-center mb-6">
          <CircleExclamation className="w-8 h-8 mr-4 text-mtn-red" />
          <span>{s.failedCount} {s.failedCount > 1 ? 'donations' : 'donation'} failed</span>
        </div>}
        <Link to={`/account/schedules/${s.scheduleID}`} className="items-center w-full">
          <Button data-test-id={`${ScheduleListTestIds.scheduleViewEdit_}${s.givematchShareCode}`}
                  className="w-full" variant="mtn-inverted" onClick={() => MTNtracking.viewEdit(user?.email)}>VIEW / EDIT</Button>
        </Link>
      </section>
    </Card>
  })

  return <WithFooter>
    <Header />
    <Page skinny className="mt-10">
      <AccountTabs active="schedules" variant={Variant.mtn} tracking={MTNtracking} />
      {isLoading && <div className="flex justify-center">
        <Spinner />
      </div>}
      {isError && <p className="font-medium text-mtn-red">
        Sorry, we weren't able to load your donations. <br />Please try again,
        and if the issue persists, <Link className="underline" to="/contact">contact us</Link>.
      </p>}
      {!isLoading && !isError && (schedules?.length ?? 0) === 0 && <>
        <p className="mb-10 font-medium">You have no schedules yet. Create one by donating.</p>
        {isCampaignOpen() && <Link to={{ pathname: '/', hash: '#donate' }}>
          <DonateNowButton />
        </Link>}
      </>}
      {!isLoading && !isError && (schedules?.length ?? 0) > 0 && <div className="grid justify-center gap-10 mb-20 sm:grid-cols-2">
        {scheduleCards}
      </div>}
    </Page>
  </WithFooter>
}
