import { Button } from '@lib/components'
import { displayTidyCurrency, UrlRoutes } from '@lib/services'
import { ActiveExperiments, Card, MTNtracking, TestVersion } from '@shamaazi/mytennights'
import { type FC, useEffect, useRef, useState, type ReactElement } from 'react'
import ShareLinkModal from '~/components/shareLinkModal/ShareLinkModal'
import { type ShareDetails } from '~/hooks/useSharePageDetails'
import { optIntoGivematch } from '~/service/givematch'
import givematch from '~/img/givematch-logo.png'
import { useAuth } from '@lib/hooks'
import { ComponentsTestId } from '@lib/testing'
import { Experiment, Variant as SplitTestVariant } from '@lib/react-ab-test/dist/mjs'

enum VoucherType {
  Amazon = 'amazon',
  SunnaMusk = 'sunnamusk'
}
const OverlappingBackground: FC = ({ children }) => {
  return <div className='py-24 px-6 sm:px-20'>
    {children}
  </div>
}

const Heading: FC = ({ children }) => {
  return <div className="text-3xl">
    {children}
  </div>
}

function getGivematchText (donation: string, total: string, isVoucherEligible: boolean): JSX.Element {
  return <Heading>
    {isVoucherEligible
      ? <> Get <span className="text-gm-green"> 30% off SunnaMusk </span> when you get 2 friends to donate </>
      : <> <span className="text-gm-green"> Your {donation} will become {total} </span> when you get 2 friends to donate </>
    }

  </Heading>
}

export const GivematchShareContent: FC<{ details: ShareDetails }> = ({ details }) => {
  const { user } = useAuth()
  const [showShareLinks, setShowShareLinks] = useState(false)

  useEffect(() => {
    MTNtracking.viewWidget()
  }, [])

  const optedIn = useRef(false)

  const fireAndForget = async (isVoucherEligible: boolean): Promise<void> => {
    setShowShareLinks(true)
    MTNtracking.widgetButtonClick(user?.email, UrlRoutes.buildShareUrl(details.shareCode), false, details.lengthChoice, details.splitChoice, details.giftAid, details.wakf, details.wakfAmount, details.fridayGiver, details.fridayGiverAmount)
    if (!optedIn.current) {
      try {
        await optIntoGivematch(details.shareCode, isVoucherEligible, VoucherType.SunnaMusk)
      } catch {
        // Swallowing errors because we don't care a huge amount.
      }

      optedIn.current = true
    }
  }

  const money = (amount: number): string => {
    return displayTidyCurrency(amount, details.currency)
  }

  const renderShareDetails = (isVoucherEligible: boolean): ReactElement => {
    return <Card variant="mtn" className="z-20 mt-10 p-6 flex m-auto md:max-w-2xl max-w-lg bg-white border font-givematch border-mtn-gray-400">
      <div className="h-full tracking-tighter text-center">
        <div>
          <img className="w-48 mt-4" src={givematch} alt="Givematch" />
          <p className="text-xl my-12 text-left font-bold leading-tight md:text-6xl text-gm-green-900">
            {getGivematchText(money(details.total), money(details.total + details.matchedAmount), isVoucherEligible)}
          </p>
          {/* eslint-disable @typescript-eslint/no-misused-promises */}
          <div className='text-center md:text-left'>
            <Button
              data-test-id={ComponentsTestId.inviteLinkShareButton}
              variant="mtn-share-button"
              className="bg-gm-yellow rounded-lg items-start border-gm-yellow text-gm-green-900 text-xs md:text-xl mt-5 w-full px-10 md:px-0"
              onClick={async () => fireAndForget(isVoucherEligible)}>
              <div className='flex gap-x-2 md:gap-x-4 md:text-3xl text-2xl md:py-4 md:px-6 w-full font-bold justify-center'>
                SHARE INVITE
              </div>
            </Button>
          </div>
        </div>
      </div>
    </Card>
  }

  return <>
    <OverlappingBackground>
      {!showShareLinks && <Experiment name={ActiveExperiments.MTNSharePageSunnamuskVoucher}>
        <SplitTestVariant name={TestVersion.control}>
          {renderShareDetails(false)}
        </SplitTestVariant>
        <SplitTestVariant name={TestVersion.variant}>
          {renderShareDetails(true)}
        </SplitTestVariant>
      </Experiment>}
      <div className="flex-col mt-10 md:mt-0 justify-around items-center px-4">
        <div className="flex flex-col justify-center items-center">
          <h1 className="mt-4 text-base text-center md:text-2xl text-white">
            You&apos;re all set for Laylatul Qadr
          </h1>
        </div>
        <p className="text-xs md:text-sm mt-1 md:mt-0 text-center text-white">
          We’ve sent you a confirmation email.
        </p>
      </div>
    </OverlappingBackground>

    {showShareLinks && <ShareLinkModal shareCode={details.shareCode} lengthChoice={details.lengthChoice} splitChoice={details.splitChoice} giftAid={details.giftAid} wakf={details.wakf} wakfAmount={details.wakfAmount} isDashboard={false} hideModal={() => setShowShareLinks(false)} fridayGiver={details.fridayGiver} fridayGiverAmount={details.fridayGiverAmount} />}
  </>
}
