import { type FC, useEffect, useState } from 'react'
import { Button } from '@lib/components'
import { useLocalStorage } from '@lib/hooks'
import { gaRetargeting, gaSnippet } from '../ga'
import { Link } from 'react-router-dom'
import { facebookPixel } from '~/facebook'
import { hideWidget, showWidget } from '~/hooks/useHiddenZendeskWidget'
import { CustomerIO, MixpanelInstance } from '@lib/services'
import { ComponentsTestId } from '@lib/testing'
import { initializeGTM } from '~/gtm'

const mixpanelProdToken = '0cd03d9cf61cefc9c2f2e771d513e099'
const mixpanelBetaToken = 'adee65a14b49e381efeb4c948c5f62f8'

export const CookieBanner: FC = () => {
  const [mounted, setMounted] = useState(false)
  const [hasAccepted, setHasAccepted] = useLocalStorage<boolean | null>('cookie-banner', null)

  useEffect(() => {
    window.onload = () => {
      if (hasAccepted === null) {
        hideWidget()
      }
    }

    if (hasAccepted !== null) {
      showWidget()
    }

    if (hasAccepted ?? false) {
      gaSnippet()
      initializeGTM()
      facebookPixel()
      gaRetargeting()
      MixpanelInstance.init(mixpanelProdToken, mixpanelBetaToken)
      CustomerIO.enable()
    }
  }, [hasAccepted])

  useEffect(() => {
    setTimeout(() => setMounted(true), 2000)
  })
  if ((hasAccepted === null) && (window.location.pathname.startsWith('/share/') || window.location.pathname.startsWith('/link/'))) {
    setHasAccepted(true)
    return <></>
  }

  return <>
    {mounted && hasAccepted === null && <div className="sticky bottom-0 p-4 bg-white opacity-90">
      <div className="flex justify-center mx-auto md:flex flex-row align-center items-center">
        <p className="flex-1 text-xs text-gm-black sm:text-sm">
          We use cookies to improve your experience on our website. <Link className="font-bold underline text-gm-black" to="/privacy-policy">More Info</Link>.
        </p>
        <div className="flex justify-center items-center">
          <Button
            data-test-id={ComponentsTestId.acceptCookies}
            variant="mtn-inverted"
            className="mx-4 bg-gm-yellow"
            onClick={() => setHasAccepted(true)}>
            OK
          </Button>
        </div>
      </div>
    </div>}
  </>
}
