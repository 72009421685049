import { DateTime } from 'luxon'

export enum ActiveCampaign {
  myTenNights = 'mtn2025',
  myTenDays = 'mtd2024'
}

export module CampaignSetup {
  // Even though actual Ramadan start date is 2025-02-28 (that is fasting starts from day 2025-02-28), we set below start date as 2025-02-27 as the night precedes day in muslim calendar,
  // the below date represents the actual 1st night payment
  export const defaultRamadanStartDate = DateTime.fromISO('2025-02-27')

  export const defaultDhulHijjahStartDate = DateTime.fromISO('2024-06-07')

  export const daysToUnlockUI = 16

  export const currentCampaignName = 'MyTenNights 2024'

  export const currentCampaign = ActiveCampaign.myTenNights

}
