import { type FC } from 'react'
import { GetDiffBetweenCampaignDate } from './Split'

export const LandingPageContent: FC = () => {
  const diff = GetDiffBetweenCampaignDate()
  return <>
    <h1 className="headline text-center font-semibold tracking-wide text-white">
      Never Miss
    </h1>
    <h1 className="headline text-center font-semibold tracking-wide text-white">
      Laylatul Qadr
    </h1>
    <p className="text-mtn-white text-sm md:text-base leading-4 mt-4 md:mt-2 lin  text-center">
      {`Automate your donations ${diff > -19 ? 'across' : 'over the last 10 nights of'}  Ramadan`}</p>
  </>
}
export const HomeHeadline: FC = () => {
  return <section className={'flex px-4 mt-28 md:mt-0 flex-col w-11/12 sm:w-3/4 items-center gap-y-1'}>
            <LandingPageContent />
  </section>
}
