import { type FC, useState } from 'react'
import { Card, getCausesTotal, SplitType, useCheckoutPlan, getTransactionFeeToolTipText, mtnSplitTexts, NightTypeOpt } from '@shamaazi/mytennights'
import { displayCurrency } from '@lib/services'
import { Button, Select } from '@lib/components'
import { MytennightsTestId, PaymentFormTestId } from '@lib/testing'
import { InfoBox } from '~/components/InfoBox'

interface SummaryCardProps {
  tip?: number
  fees?: number
  togglePlanFees?: () => void
  showTip?: boolean
}

const SplitSummary: FC = () => {
  const [editing, setEditing] = useState<boolean>(false)
  const { plan, setPlan } = useCheckoutPlan()
  const splitTypes = [SplitType.Equally, SplitType.More27]
  plan.totalNight.key === NightTypeOpt.AllAvailable ? splitTypes.splice(1, 0, SplitType.MoreLastTenOddNights) : splitTypes.splice(1, 0, SplitType.MoreOdd)

  return <section className="border-b px-5 checkout-card-border pb-4">
    <div className="flex justify-between w-full items-center mb-4">
      <h3 className="text-mtn-blue-800 text-lg font-semibold">Your donation split</h3>
      <Button type="button" variant="mtn-tertiary" onClick={() => setEditing(!editing)}>
        {!editing ? 'Edit' : 'Done'}
      </Button>
    </div>
    {!editing ? <p className="text-sm">{mtnSplitTexts[plan.split]}</p>
      : <Select
        value={plan.split}
        onChange={(e) => setPlan({ ...plan, split: e.target.value as SplitType })}
        className="w-full rounded-md text-xs"
        variant="mtn">
        {splitTypes.map(splitType => <option key={splitType} value={splitType} className="mb-4 border-2">{`${mtnSplitTexts[splitType]}`}</option>)}
      </Select>}
  </section>
}

export const CheckoutSummaryCard: FC<SummaryCardProps> = ({ tip = 0, fees = 0, children, showTip = false }) => {
  const [expanded, setExpanded] = useState(false)
  const { plan, setPlan } = useCheckoutPlan()

  const toggleFees = (): void => setPlan({ ...plan, paymentDetails: { ...plan.paymentDetails, payFees: !plan.paymentDetails.payFees } })

  const causeAmounts = plan.charity.causes.filter(cause => plan.causes[cause.cause_id] > 0).map((cause) => {
    return <div key={cause.cause_id}
                className={'transform my-4 transition-colors duration-500 flex justify-between text-sm text-mtn-gray}'}>
      <span>{cause.title}</span>
      <span>{displayCurrency({ amount: plan.causes[cause.cause_id], currency: plan.charity.currency })}</span>
    </div>
  })

  const causeTotal = getCausesTotal(plan)

  return <div className="flex flex-col w-full text-mtn-blue-900 mt-5 md:mt-8 lg:mt-0">
    <Card variant="mtn" className="xl:mx-0 md:mt-0 checkout-card-border border-opacity-10 border-1 rounded-lg bg-mtn-white flex flex-col gap-y-2 md:gap-y-6 sm:gap-y-0 pt-4 pb-8">
      <section className="flex items-center justify-between border-b checkout-card-border border-opacity-10 pb-3 px-5">
        <h3 className="text-lg font-semibold">Summary</h3>
        <Button data-test-id={PaymentFormTestId.toggleExpandSummaryBox} type="button" variant="mtn-tertiary" onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Hide' : 'Show'}
        </Button>
      </section>
      {expanded && <>
        <SplitSummary />
        <section className="border-b px-5 checkout-card-border border-opacity-10">
          <h3 className={'text-mtn-blue-800 text-lg font-semibold mb-4'}>Causes you've chosen</h3>
          {causeAmounts}
        </section>
        <section className="border-b px-5 checkout-card-border text-sm">
        <h3 className="mb-4 text-lg font-semibold">Breakdown</h3>
        <div className="flex justify-between my-4">
          <span className='font-bold'>Subtotal (causes)</span>
          <span className='font-bold'>{displayCurrency({ amount: causeTotal, currency: plan.charity.currency })}</span>
        </div>
        {showTip && <div className="flex justify-between my-4">
          <span>MyTenNights Tip</span>
          <span data-test-id={PaymentFormTestId.tipField}>{displayCurrency({ amount: tip, currency: plan.charity.currency })}</span>
        </div>}
        <div className="relative flex justify-between my-4 ">
          <div>
            <span>Transaction Fee</span>
            <InfoBox> {getTransactionFeeToolTipText()} </InfoBox>
          </div>
          <div>
            <Button className="mr-4" type="button" variant="mtn-tertiary" onClick={toggleFees}>
              {plan.paymentDetails.payFees ? 'Remove' : 'Add'}
            </Button>
            <span>{displayCurrency({
              amount: fees ?? 0,
              currency: plan.charity.currency
            })}</span>
          </div>
        </div>
      </section>
      </>}
      <section className="flex items-center justify-between border-mtn-gray-300 text-xl font-semibold text-mtn-blue-300 px-5">
        <h3>TOTAL:</h3>
        <span
          data-test-id={MytennightsTestId.totalText}>{displayCurrency({
            amount: causeTotal + tip + fees,
            currency: plan.charity.currency
          })}</span>
      </section>
      <section className="flex flex-col items-center">
        {children}
      </section>
    </Card>
  </div>
}
