import { Button } from '@lib/components'
import { type FC } from 'react'
import { MytennightsTestId } from '@lib/testing'
import { MTNtracking } from '@shamaazi/mytennights'

// DonateNowButton wraps a standard button and adds tracking to it
const DonateNowButton: FC<{ disabled?: boolean, onClick?: () => void }> = ({ disabled = false, onClick = null }) => {
  const click = (): void => {
    MTNtracking.donateNow()
    if (onClick !== null) {
      onClick()
    }
  }

  return <Button data-test-id={MytennightsTestId.donateNowButton} disabled={disabled} variant="mtn-primary" onClick={click}>DONATE</Button>
}

export default DonateNowButton
