import { type FC } from 'react'
import { Link } from 'react-router-dom'
import { CircleChecked, Page } from '@lib/components'
import { Header, WithFooter } from '~/components'
import { MTNtracking } from '@shamaazi/mytennights'
import { useAuth } from '@lib/hooks'

export const FAQ: FC = () => {
  const { user } = useAuth()

  function scrollToSection (id: string): void {
    document.getElementById(id)?.scrollIntoView()
  }

  return <WithFooter>
    <Header />
    <Page skinny className="px-10 my-16 font-medium">
      <h1 className="my-8 text-2xl font-semibold md:text-4xl text-mtn-blue-800">FAQs</h1>

      <section>
        <p>Everything you need to know about MyTenNights. Jump to:</p>
        <div className="mt-4 font-semibold text-mtn-blue md:flex">
          <ul className="flex-1">
            <li className="mt-2 underline cursor-pointer md:mt-0" onClick={() => { scrollToSection('about') }}>About</li>
            <li className="mt-2 underline cursor-pointer md:mt-4" onClick={() => { scrollToSection('donation') }}>Donation process</li>
            <li className="mt-2 underline cursor-pointer md:mt-4" onClick={() => { scrollToSection('postDonation') }}>Post donation</li>
            <li className="mt-2 underline cursor-pointer md:mt-4" onClick={() => { scrollToSection('moreInfo') }}>More Information</li>
          </ul>
          <ul className="flex-1">
            <li className="mt-2 underline cursor-pointer md:mt-0" onClick={() => { scrollToSection('giftAidTax') }}>GiftAid (UK) &#38; tax-deductibility (USA)
            </li>
            <li className="mt-2 underline cursor-pointer md:mt-4" onClick={() => { scrollToSection('security') }}>Security</li>
          </ul>
        </div>

      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section id="about">
        <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtn-blue-800">About MyTenNights</h2>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">What is MyTenNights?</h3>
        <p className="mt-4">MyTenNights is an online platform that automates your donations over the last ten nights of Ramadan, so that you never miss giving on Laylatul Qadr
          again.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Is MyTenNights a registered charity?</h3>
        <p className="mt-4">No, we are a limited company dedicated to building culture-changing products for charities.</p>

      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>
      <section id="about">
        <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtn-blue-800">Setting Up Your Donation</h2>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How can I set up my donation?</h3>
        <p className="mt-4">You can easily set up your donation by visiting our donation page <a className="underline text-mtn-blue" href="https://mytennights.com" target="_blank" rel="noopener noreferrer">here</a>. Follow the simple steps provided to complete the process securely.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Can I set up my donation over the phone?</h3>
        <p className="mt-4">Unfortunately, we are unable to set up donations over the phone. For security reasons and to ensure accuracy, we encourage all donors to utilise our online donation platform.</p>

      </section>
      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>
      <section id="donation">
        <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtn-blue-800">The donation process</h2>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Do we deduct anything from your donation?</h3>
        <p className="mt-4">No, we don't deduct anything from your donation. 100% of your donation goes directly to the charity.</p>
        <p className="mt-4">During checkout, you have the option to cover our third-party fees, such as card processing fees. If you opt not to cover these fees, they will be deducted from your donation.</p>
        <p className="mt-4"><a className="underline text-mtn-blue"
            href="https://www.mytennights.com/fees">Click here</a> to find out more about how your donations travel from your account to the charity.
</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">What is the Transaction Fee?</h3>
        <p className="mt-4">This is our best estimation of what the 3rd party card processing and fees will be for your country. Paying this fee is optional and it can be removed before you check out. Please note that if you do remove the transaction fee, this will instead be deducted from your total donation, reducing the amount that the charity receives.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">What is the MyTenNights Tip?</h3>
        <p className="mt-4">The tip is an optional payment which goes directly to the ongoing running and development of the MyTenNights platform. This can be decreased, increased or removed completely at your discretion.
          Please note that the tip is taken with your first night's payment, in full and not spread across the ten nights like your donation.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How can I split my donations?</h3>
        <p className="mt-4">There are 3 ways to split your donations:</p>
        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              Equally across all nights
            </div>
          </li>
          <li className="flex items-start mt-2">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              More on odd nights
            </div>
          </li>
          <li className="flex items-start mt-2">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              More on the 27th night
            </div>
          </li>
        </ul>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Can I choose my own start date for my donations?</h3>
        <p className="mt-4">Certainly! We completely understand that our donors may observe Ramadan on slightly different dates. That's why we provide the option to select your start date. Since Ramadan's timing is determined by the sighting of the moon, this feature will be available closer to the start of Ramadan. When you proceed with the donation process, you'll find the option to choose from two different start dates on the 'Details' page.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How do you calculate the nightly splitting of donations?</h3>
        <p className="mt-4">We divide your donations according to your instructions and schedule. Please note that due to limitations in splitting pennies/cents into fractions, you may occasionally notice a minor rounding up or down on the last night of your schedule to ensure the full donation is processed. If you have any concerns or notice anything unusual in your schedule, please <a className="underline text-mtn-blue"
            href="https://www.mytennights.com/contact">contact our Donor Care</a> team by phone or email. They will be more than happy to assist you.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">What time do you process the nightly donations?</h3>
        <p className="mt-4">We process each donation approximately one hour after sunset in your local time, every night.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">What happens if my donation is rejected?</h3>
        <p className="mt-4">We'll promptly notify you via email if your payment is rejected. You can easily retry the payment by logging into your <a className="underline text-mtn-blue"
            href="https://www.mytennights.com/account/schedules">Donor Dashboard</a></p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">I’ve caught this late. There are only 5 days left of the last 10 nights. Can I still donate?</h3>
        <p className="mt-4">Yes, you can schedule donations up until 1am in your local time on the 29th night of Ramadan.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Why is my first payment taken on the 20th night of Ramadan, and not on the 21st?</h3>
        <p className="mt-4">Ramadan is always either 29 days or 30 days each year. Until the moon is sighted, we have no way of knowing for sure. For this reason,
          the last 10 nights can either start on the 20th night (when there are 29 days in Ramadan) OR on the 21st night (when there are 30 days in Ramadan).
          At MyTenNights, we want to make sure that you’re covered in either eventuality, and for that reason,
          we’ll always start automating your donations from your 20th night.</p>
        <p className="mt-4">In addition, we provide the option of donating on the possible 30th night. This will mean you can automate up to 11 payments across your 20th,
          21st, 22nd, 23rd, 24th,25th,26th,27th,28th,29th & 30th nights of Ramadan. We highly recommend you select the option to donate on the 30th night in
          order to be covered across all the nights that could be your last 10 nights.</p>
        <p className="mt-4">If you do opt in for the 30th night, and Ramadan happens to be 29 days that year, your final payment will be taken on Eid.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How the All Nights option work?</h3>
        <p className="mt-4">We're excited to introduce a new feature that allows you to extend your charitable giving over the entire month. We understand the significance of every night during this sacred time, and now, you have the opportunity to make a difference each and every evening.</p>
        <p className='mt-4'>Here's how it works:</p>
        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
            Select the All Nights Option: Choose this option to spread your donations across the entirety of Ramadan.
            </div>
          </li>
          <li className="flex items-start mt-2">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
            Choose How to Split Your Donation: Decide whether you want to split your donation equally over all nights, focus more on the last 10 nights, or perhaps emphasise the blessed 27th night.
            </div>
          </li>
          <li className="flex items-start mt-2">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
            Select a Charity of Your Choice: Pick from our list of trusted charities that resonate with your values and causes.
            </div>
          </li>
          <li className="flex items-start mt-2">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
            Decide Your Allocation: Allocate your donation amount towards different causes within your chosen charity, ensuring your contribution aligns with your passions and priorities.
            </div>
          </li>
        </ul>
        <p className="mt-4">What happens if there is a 30th Night? </p>
        <p className='mt-4'>Ramadan spans either 29 or 30 days each year, with the exact duration dependent on the sighting of the moon. Should Ramadan extend to a 30th night, you will be automatically enrolled in this payment. The donation amount for the 30th night will mirror that of your 28th night schedule. For instance, if your donation amount on the 28th night was £20, your 30th night donation will also be £20. For a detailed breakdown of your nightly donations, you can always refer to your donor dashboard.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How does the Friday Giver option work?</h3>
        <p className="mt-4">We're thrilled to announce an exciting new feature that empowers you to make a difference every single Friday throughout the year. Recognising the significance of regular donations, especially on the blessed day of Friday, we've introduced a convenient and impactful way for you to contribute consistently.</p>
        <p className='mt-4'>Here's how it works:</p>
        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
            Friday Giver Donations: Your donations for the Friday Giver initiative will kick off on the first Friday following Ramadan and will continue seamlessly until you choose to opt out.
            </div>
          </li>
          <li className="flex items-start mt-2">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
            Opt-In Flexibility: During the checkout process while setting up your Ramadan donation schedule, you'll have the option to seamlessly opt in or out of the Friday Giver program.
            </div>
          </li>
          <li className="flex items-start mt-2">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
            Charity Allocation: Your contributions will support the MultiPot Charity, a carefully curated selection of charities that exemplify unparalleled clarity, transparency, accountability, and impact. Rest assured, these charities are already trusted partners of MyTenNights.
            </div>
          </li>
          <li className="flex items-start mt-2">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
            Donor Control: Gain full access to your donation history and preferences through your donor dashboard. You'll have the flexibility to review and manage your donations as needed, including the option to opt out at any time.
            </div>
          </li>
        </ul>
        <p className='mt-4'>This innovative feature is designed to provide you with greater flexibility and convenience throughout the year, ensuring that your generosity knows no bounds. By participating in the Friday Giver,  you'll not only maximise your donations but also reap the abundant blessings of consistent giving on this sacred day.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">What happens if there is a 30th Night in Ramadan?</h3>
        <p className="mt-4">Ramadan spans either 29 or 30 days each year, with the exact duration dependent on the sighting of the moon. Should Ramadan extend to a 30th night, you will be automatically enrolled in this payment. The donation amount for the 30th night will mirror that of your 28th night schedule. For instance, if your donation amount on the 28th night was £20, your 30th night donation will also be £20. For a detailed breakdown of your nightly donations, you can always refer to your donor dashboard.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How do I opt out of the 30th Night?</h3>
        <p className="mt-4">If you would like to opt out of the 30th Night, please login to your <a className="underline text-mtn-blue"
            href="https://www.mytennights.com/account/schedules">Donor Dashboard</a> and head to your schedule. At the bottom of the page you will see the 30th Night donation, select the opt out option. No payment will be taken for the 30th Night.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Can I automate my donations so that next year I don’t need to set up another donation schedule?</h3>
        <p className="mt-4">Absolutely! On check out, you can opt to automate your donations each Ramadan. We will copy this year's donation to the same charity and split it over the last ten nights of Ramadan, just like you’ve chosen this year. You can amend or cancel this at any time, and before every Ramadan we’ll send you a reminder with your options.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How do I know my money is going to the charity?</h3>
        <p className="mt-4">MyTenNights is registered under the Fundraising Regulator. We are wholly transparent and welcome donors to query with charities if their donation was ultimately received in full. We also issue an Annual Report each year. If you would like us to connect you directly with our contact at your chosen charity, we are happy to do so. please <a className="underline text-mtn-blue"
            href="https://www.mytennights.com/contact">contact our Donor Care</a> team by phone or email. They will be more than happy to assist you.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">My bank statement says that donations are not coming out on the right days. What is happening?!?</h3>
        <p className="mt-4">Please note, for non-digital banks, it may take some time/days for the transaction to appear on your bank statement. Some banks show transactions instantly while others may take up to 5 working days to appear. Even though donations are processed nightly, it may be that your bank waits 5 days to populate them, and may even show all on the same day, despite the transaction happening on different days. E.g. you may pay for a coffee on a Tuesday, but the bank only shows it on your statement as a payment on Friday. Rest assured however – we take your money on the correct night at the correct time after sunset, each night!
        </p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Where does my donation go if I select ‘Spread Donations Across Charities with One Click’?</h3>
        <p className="mt-4">By selecting this option, we hope to raise the bar for all charities across the board by redistributing donations to verified charities on our platform that have continuously displayed the highest level of clarity, transparency, accountability, and impact. You will receive feedback on how the funds have been allocated in our annual report.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">I want to do a second donation on behalf of someone else (e.g. for my mum)</h3>
        <p className="mt-4">Certainly! Simply make the intention for the individual you wish to donate for and initiate the donation process once more.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Can I donate if I live outside of the countries listed?</h3>
        <p className="mt-4">Of course! Please note that the transaction will be calculated in the native currency of the charity. So, for UK charities, all donations will be in GBP, for US charities they will be in USD, and so forth.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">What payment options do you offer? </h3>
        <p className="mt-4">We currently only accept card payments, Google Pay or Apple Pay. However, we are always working to improve our donor’s experience on the platform and hope to add more options in the future.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Why is my donation payment in a different currency to my location?</h3>
        <p className="mt-4">All payments are processed in the native currency of the charity. For UK charities, donations will be in GBP, for US charities, they will be in USD, and so forth. This structure is built on a transparent process where the Donor Advised Fund (DAF) manages all funds before onwards gifting your donations to the chosen charity.</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section id="postDonation">
        <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtn-blue-800">Post donation</h2>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How can I track if my donations have been processed?</h3>
        <p className="mt-4">You have access to your own user dashboard! Just head to the homepage and click on the <Link className="underline text-mtn-blue" to="account/schedules"
          onClick={() => MTNtracking.myProfile(user?.email)}>My Profile</Link> link. Enter your email address. You'll then receive an email containing your single-use passcode. Once you've entered the code, you'll be able to view your scheduled payments, track those that have been processed, identify any failed transactions, and even pinpoint the exact time after sunset when your payments were taken.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">I want to edit my account</h3>
        <p className="mt-4">Log into your <Link className="underline text-mtn-blue" to="account/schedules"
          onClick={() => MTNtracking.myProfile(user?.email)}>Donor Dashboard</Link> and any options that can be edited will be available.
          Where you cannot edit a field, contact our Donor Care Team on <a className="underline text-mtn-blue" href="mailto:contact@mytennights.com">contact@mytennights.com</a> who will be able to assist you with this.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">I want to edit my donation</h3>
        <p className="mt-4">Once a schedule is created, you are unable to make changes to it. If you wish to make any changes, you must cancel your schedule and create a new one in
          your <Link className="underline text-mtn-blue" to="account/schedules"
            onClick={() => MTNtracking.myProfile(user?.email)}>Donor Dashboard</Link>.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">I want to cancel my donation</h3>
        <p className="mt-4">Log into your <Link className="underline text-mtn-blue" to="account/schedules"
          onClick={() => MTNtracking.myProfile(user?.email)}>Donor Dashboard</Link> where you will be able to cancel your schedule.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">I want to reactivate my donation</h3>
        <p className="mt-4">Log into your <Link className="underline text-mtn-blue" to="account/schedules"
          onClick={() => MTNtracking.myProfile(user?.email)}>Donor Dashboard</Link> where you will be able to reactivate your schedule. </p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">I want to cancel the auto opt in for an automation schedule for next Ramadan</h3>
        <p className="mt-4">Log into your <Link className="underline text-mtn-blue" to="account/schedules"
          onClick={() => MTNtracking.myProfile(user?.email)}>Donor Dashboard</Link> where you will be able to cancel the opt-in for auto enrolment next year. </p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">I want to activate/reactivate the auto opt in for an automation schedule for next Ramadan</h3>
        <p className="mt-4">Log into your <Link className="underline text-mtn-blue" to="account/schedules"
          onClick={() => MTNtracking.myProfile(user?.email)}>Donor Dashboard</Link> where you will be able to activate/reactivate the opt-in for auto enrolment next year. </p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">I want to refund my donation</h3>
        <p className="mt-4">Please contact our Donor Care Team on <a className="underline text-mtn-blue" href="mailto:contact@mytennights.com">contact@mytennights.com</a> who
          will be able to assist you with this.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">I didn’t receive a confirmation email or nightly donation receipt</h3>
        <p className="mt-4">Sometimes, these emails can be found in your junk / spam mail. Please check these. If there is still no confirmation email, please contact our Donor
          Care Team on <a className="underline text-mtn-blue" href="mailto:contact@mytennights.com">contact@mytennights.com</a> who will be able to assist you with this.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">What is my password?</h3>
        <p className="mt-4">MyTenNights does not require you to set your own password. Instead, a one time password for your account is issued each time you want to log in. Click <Link className="underline text-mtn-blue" to="account/schedules"
          onClick={() => MTNtracking.myProfile(user?.email)}>My Profile</Link> on the home page and enter the email address you used to register last year. A passcode will be sent to you. Please note the password can only be used once and does expire if not used within 15 minutes.</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section id="giftAidTax">
        <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtn-blue-800">GiftAid and tax-deductibility </h2>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Do donations to my charity of choice qualify for GiftAid in the UK?</h3>
        <p className="mt-4">Yes. Donations to MyTenNights qualify for GiftAid in the UK. Voluntary contributions to MyTenNights (clearly marked as separate from your
          charity donation) do not qualify for GiftAid as we are a limited company.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Are donations to my charity of choice tax-deductible in the USA, Canada and Australia?</h3>
        <p className="mt-4">Yes. Donations to our charity partners are tax-deductible in the USA, Canada and Australia.</p>
        <p className="mt-4">Voluntary contributions to MyTenNights (clearly marked as separate from your charity donation) do not qualify for tax-deductions as we are a limited
          company.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Will I receive a Tax Receipt?</h3>
        <p className="mt-4">Yes. You should receive a tax receipt before the end of the tax yearand you should not need to take any further action. However, if you need one sooner, please contact our Donor Care Team on <a className="underline text-mtn-blue" href="mailto:contact@mytennights.com">contact@mytennights.com</a> who will issue this for you.  Please note that the receipt is issued by our DAF partner, relevant to your country as your donation was essentially received by them and onward gifted to your chosen charity.</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section id="security">
        <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtn-blue-800">Security</h2>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Is my payment secure?</h3>
        <p className="mt-4">Yes. We take security seriously and meet the security standards of the EU PSD2 directives. All our payments are processed through our payment provider,
          Stripe. We do not store any card details on our database.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Is my data safe?</h3>
        <p className="mt-4">Yes. We take your data seriously. Please see our <Link className="underline text-mtn-blue" to="/privacy-policy">privacy
          policy</Link>. We do not share your data with any third party without your permission unless it is required to access the service. We will only contact you or pass on
          your contact details to your charity of choice if you explicitly ask us to.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Can I remain anonymous?</h3>
        <p className="mt-4">All donations are anonymous to the public. You need to provide your name to validate your payment card, opt-in to marketing, and to request GiftAid or a
          tax receipt. Charities will only receive your full name if you opt-in to share your details.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Will you pass on my data to charities?</h3>
        <p className="mt-4">Only if you tell us to (tick the opt-in box). We’ll never pass on your data without your consent. There is an exception for a handful of charities that
          pass our enhanced vetting. These are charities who, for legitimate reasons, need a record of all individuals who have donated to their charity. These charities must pass
          our due diligence and sign our data sharing agreement, which means they can never contact you, or do anything with this data, other than the admin activities stipulated
          in our sharing agreement.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Will you sell or trade my data?</h3>
        <p className="mt-4">Never. We are fully GDPR compliant. We’ll never do anything to annoy you. Please see our Privacy Policy <Link
          className="underline text-mtn-blue" to="/privacy-policy">here</Link>.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Will my data be sold, traded, or mishandled in any way?</h3>
        <p className="mt-4">Rest assured, we are fully GDPR compliant and committed to respecting your privacy. We prioritise your peace of mind and promise not to engage in any practices that might cause annoyance. For further details, feel free to review our Privacy Policy <Link className="underline text-mtn-blue" to="/privacy-policy">here</Link>.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Transparency and Accountability</h3>
        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Where can I read your terms and conditions?</h3>
        <p className="mt-4">Our Terms and Conditions are available to read <Link className="underline text-mtn-blue" to="/terms-and-conditions">here</Link>.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Where can I read your privacy policy?</h3>
        <p className="mt-4">Our Privacy Policy is available to read <Link className="underline text-mtn-blue" to="/privacy-policy">here</Link>.</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <section id="gmmtn">
        <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtn-blue-800">Givematch x MyTenNights</h2>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">What is Givematch?</h3>
        <p className="mt-4">Givematch is an online platform where donors have their donations matched based on their referrals. Donors share their favourite campaigns with their
          friends - when two of their friends donate, they have their (original) donation matched.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">What is Givematch x MyTenNights?</h3>
        <p className="mt-4">We have partnered with Givematch so donors can get their MyTenNights donations matched.  On the MyTenNights checkout page you will have the option to opt-in to Givematch.
          You will be emailed a unique link to share and if 2 friends donate via this link your donation will be doubled (usually up to £200 but this does vary by country). There are limited matched funds available so this opportunity is only available while there are funds in the match pot.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">If I share my link with 2 people do they have to donate the same amount as me?</h3>
        <p className="mt-4">Those who donate through your link can donate any amount to their chosen charity to be eligible. It does not need to be the same as your donation. If you successfully refer two friends to donate and earn a matched donation, it will usually go to the charity you made your original donation to.
        <br/>
        However, in exceptional circumstances and at their own discretion, MyTenNights and Givematch also reserve the right to allocate the matched funding to other worthy charitable causes.
        </p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">If I share my link with 2 people do they have to donate to the same charity as me?</h3>
        <p className="mt-4">Those who donate through your link can donate to any charity of their choice. It does not need to be the same charity as you.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How do I find out if my donation got doubled?</h3>
        <p className="mt-4">Log into your <Link className="underline text-mtn-blue" to="account/schedules"
          onClick={() => MTNtracking.myProfile(user?.email)}>Donor Dashboard</Link> where you will be able to see the amount that is eligible for matching, how many people have used your link to donate, and you can also access your share code link again. Once you have 2 friends donate using your link, the donation amount will be matched and onward gifted to your chosen charity.</p>

        <h3 className="mt-8 text-lg font-bold md:text-xl text-mtn-blue-800">How does MyTenNights sustain itself?</h3>
        <p className="mt-4">We sustain ourselves through voluntary tips from our users, without taking a cut of public donations or pricing services as a percentage of funds raised. When you donate, rest assured that 100% of your contribution reaches the intended charity, minus any fees imposed by third parties beyond our control, such as card processing fees.</p>
        <p className="mt-4">In our commitment to transparency, we want you to know that we never handle your donation directly. Instead, it flows securely from your bank account to an independent third-party donation escrow known as a Donor Advised Fund (DAF). These DAFs act as a safeguard, ensuring responsible handling of your funds by an independent entity and delivering them to your chosen charity. Our role is simply to provide the software that empowers charities, while maintaining a clear separation from the financial flow of your donation.</p>
        <p className='mt-4'>For complete transparency, we encourage all donors, charities, and corporate partners to communicate directly with our designated DAF partners for any additional inquiries or clarifications.</p>
        <p className="mt-4">The details for our DAF partners are:</p>
        <ul>
          <li className="flex flex-col items-start mt-4">
            <p>United Kingdom:</p>
            <p>Mercy Mission UK, registered charitable organization number 1122922</p>
          </li>
          <li className="flex flex-col items-start mt-4">
            <p>Canada:</p>
            <p>KBF CANADA, registered charitable organization number 76978 4893 RR0001</p>
          </li>
          <li className="flex flex-col items-start mt-4">
            <p>United States of America:</p>
            <p>The King Baudouin Foundation, registered charitable organization number 58-2277856</p>
          </li>
          <li className="flex flex-col items-start mt-4">
            <p>Australia:</p>
            <p>Myriad Australia Limited, registered charitable organization number ABN 20 640 318 636</p>
          </li>
        </ul>
      </section>
      <section id="moreInfo">
        <h2 className="my-4 text-xl font-semibold md:text-2xl text-mtn-blue-800">More Information</h2>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Are you independent?</h3>
        <p className="mt-4">Yes, we are independent, and MyTenNights is registered under the Fundraising Regulator, ensuring our commitment to ethical fundraising practices.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Who runs MyTenNights?</h3>
        <p className="mt-4">MyTenNights is run by Shamaazi Ltd. We are a group of tech entrepreneurs from the UK that have decided to apply the power of technology to giving. The
          result is MyTenNights. We believe we can transform the world into a better place much faster by applying technology to the charitable sector.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How long has MyTenNights been around?</h3>
        <p className="mt-4">Since 2016, we've been dedicated to our mission of making a positive impact. Over the years, we've raised over $100,000 million for charities worldwide, thanks to the generous contributions of over 200,000 donors like you.</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How can I support MyTenNights?</h3>
        <p className="mt-4">At the end of the donation process, you have the option to include a voluntary contribution. Thank you for considering this additional support!</p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How can I volunteer or work at MyTenNights?</h3>
        <p className="mt-4">Any opportunities will be advertised on our Careers Page <a className="underline text-mtn-blue" href="https://shamaazi.com/careers/"
          target="_blank"
          rel="noopener noreferrer">https://shamaazi.com/careers</a></p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">Where can I follow you on social media?</h3>
        <ul>
          <li className="flex items-start mt-4">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              <a className="underline text-mtn-blue" href="https://facebook.com/mytennights" target="_blank" rel="noopener noreferrer">Facebook</a>
            </div>
          </li>
          <li className="flex items-start mt-2">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              <a className="underline text-mtn-blue" href="https://instagram.com/mytennights" target="_blank" rel="noopener noreferrer">Instagram</a>
            </div>
          </li>
          <li className="flex items-start mt-2">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
              <a className="underline text-mtn-blue" href="https://twitter.com/mytennights" target="_blank" rel="noopener noreferrer">Twitter</a>
            </div>
          </li>
        </ul>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How can I become a charity partner next year?</h3>
        <p className="mt-4">For charity inquiries, please feel free to reach out to our dedicated Charity Support team at <a className="underline text-mtn-blue" href="mailto:contact@mytennights.com">contact@mytennights.com</a></p>

        <h3 className="mt-8 text-lg md:text-xl text-mtn-blue-800">How do I access donor support?</h3>
        <p className="mt-4">You can contact our Donor Care Team in the following ways:</p>
        <ul>
          <li className="flex items-start mt-2">
            <CircleChecked className="mt-1 mr-4 text-mtn-blue" />
            <div className="flex-1">
             By Email - <a className="underline text-mtn-blue" href="mailto:contact@mytennights.com">contact@mytennights.com</a>
            </div>
          </li>
        </ul>
      </section>
    </Page>
  </WithFooter>
}
