import { Country } from '@lib/services'

export const charityDafPatnerDetails = new Map<Country, { name: string, number: string }>([
  [Country.GB, {
    name: 'Mercy Mission UK',
    number: '1122922'
  }
  ],
  [Country.AU, {
    name: 'Myriad Australia Limited',
    number: 'ABN 20 640 318 636'
  }
  ],
  [Country.US, {
    name: 'The King Baudouin Foundation',
    number: '58-2277856'
  }
  ],
  [Country.CA, {
    name: 'KBF CANADA',
    number: '76978 4893 RR0001'
  }
  ]
])
