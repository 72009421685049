import { getSplitBreakdown, type Plan } from '@shamaazi/mytennights'

import { getBaseFee } from '@lib/services'

export const getTotalFees = (plan: Plan, tip: number): number => {
  if (!plan.paymentDetails.payFees) {
    return 0
  }

  const breakdown = getSplitBreakdown(plan)

  return breakdown.nights.map(n => {
    if (!n.isOpen) {
      return 0
    }
    const t = n.number === plan.firstNight ? tip : 0

    return Math.ceil((n.amount + t + getBaseFee(plan.charity.currency)) / 0.971 - n.amount - t)
  }).reduce((acc, v) => acc + v, 0)
}
