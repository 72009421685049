import { type ChangeEvent, type FC, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { useAuth } from '@lib/hooks'
import { CircleChecked, CircleCrossed, Page, Spinner } from '@lib/components'
import { AccountTabs, Card, getMarketingPreferences, type Preference, type Preferences, updateMarketingPreference, type UpdateMarketingPreferenceTemplate, Variant, MTNtracking, getChannelValue } from '@shamaazi/mytennights'
import { Header, WithFooter } from '~/components'
import { MarketingChannel, MarketingSource, MarketingTransport } from '@lib/services'
import { getMarketingPreferenceIds, MarketingTestIds } from '@lib/testing'

export const MarketingPreferences: FC = () => {
  const [preferences, setPreferences] = useState<Preferences>({ email: { channels: {}, charity_names: {} } })
  const [showUpdatedConfirmation, setShowUpdatedConfirmation] = useState(false)
  const [showUpdatedError, setShowUpdatedError] = useState(false)
  const { user } = useAuth()

  const fetchMarketingPreferences = async (): Promise<void> => {
    const res = await getMarketingPreferences()
    setPreferences(res)
  }

  const queryClient = useQueryClient()

  const { isLoading, isError } = useQuery('preferences', fetchMarketingPreferences, { refetchOnWindowFocus: false })

  const { mutate: updatePreference, isLoading: isUpdating } = useMutation(async (data: UpdateMarketingPreferenceTemplate): Promise<Preference> => {
    return await updateMarketingPreference(data)
  }, {
    onSuccess: (_, data: UpdateMarketingPreferenceTemplate) => {
      MTNtracking.preferencesUpdated(user?.email, {
        hearFromUs: getChannelValue(data, MarketingChannel.updates),
        productUpdates: getChannelValue(data, MarketingChannel.product),
        charity: data.source === 'charity' ? data.channel : undefined,
        charityValue: data.source === 'charity' ? data.value : undefined
      }
      )
      queryClient.invalidateQueries('preferences').catch(() => {})
      setShowUpdatedConfirmation(true)
      setTimeout(() => setShowUpdatedConfirmation(false), 2000)
    },
    onError: () => {
      setShowUpdatedError(true)
      setTimeout(() => setShowUpdatedError(false), 2000)
    }
  })

  function getCommunicationCheckbox (labelKey: string | undefined, isChecked: boolean | undefined, transport: MarketingTransport, identifier: string, channel: MarketingChannel, source: MarketingSource, message: string, additionalProps?: Record<string, any>): JSX.Element {
    const marketingPreferenceId = getMarketingPreferenceIds(source, channel)
    return <label key={labelKey} className="flex items-center mt-4">
      <input
        className={`mr-4 ${isUpdating ? 'animate-pulse' : ''}`}
        type="checkbox"
        data-test-id={MarketingTestIds.optIn_ + marketingPreferenceId}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          updatePreference({
            transport,
            identifier,
            channel,
            source,
            value: e.target.checked,
            ...additionalProps
          })
        }}
        checked={isChecked}
        disabled={isUpdating} />
      {message}
    </label>
  }

  function getCommunicationsSection (title: string, channel: MarketingChannel, source: MarketingSource): JSX.Element {
    return <section className="p-10 border-b border-mtn-gray-300">
      {getCommunicationCheckbox(undefined, preferences.email.channels?.[source]?.[channel], MarketingTransport.email, user!.email, channel, source, title)}
    </section>
  }

  return <WithFooter>
    <Header />
    <Page skinny className="mt-10">
      <AccountTabs active="preferences" variant={Variant.mtn} tracking={MTNtracking} />

      {showUpdatedConfirmation && <Card variant="mtn" className="fixed inset-x-0 z-10 max-w-lg p-4 mx-auto text-center bg-white top-24 lg:top-36">
        <span className="mr-2">Your preferences have been updated</span>
        <CircleChecked className="inline-block align-text-bottom text-mtn-blue" />
      </Card>}

      {showUpdatedError && <Card variant="mtn" className="fixed inset-x-0 z-10 max-w-lg p-4 mx-auto text-center bg-white top-24 lg:top-36">
        <span className="mr-2">Sorry, we were unable to update your preferences</span>
        <CircleCrossed className="inline-block align-text-bottom text-mtn-red" />
      </Card>}

      {(isLoading) && <div className="flex justify-center">
        <Spinner />
      </div>}

      {isError && <p className="font-medium text-mtn-red">
        Sorry, we weren't able to load your marketing preferences. <br />Please try again,
        and if the issue persists, <Link className="underline" to="/contact">contact us</Link>.
      </p>}

      {!isLoading && !isError && <div>
        <Card variant="mtn" className="mx-4 my-8 font-medium bg-white">
          <section className="p-10 border-b border-mtn-gray-300">
            <h2 className="text-lg text-mtn-blue-800">MyTenNights Preferences</h2>
            <p className="mt-4 text-sm">I am happy to be contacted by MyTenNights for the following purposes.</p>
          </section>

          {getCommunicationsSection('Be the first to know about MyTenNights\' causes, updates and news', MarketingChannel.updates, MarketingSource.mytennights)}
        </Card>

        <Card variant="mtn" className="mx-4 my-8 font-medium bg-white">
          <section className="p-10 border-b border-mtn-gray-300">
            <h2 className="text-lg text-mtn-blue-800">Charity Preferences</h2>
            <p className="mt-4 text-sm">I am happy to be contacted by these charities with information on their projects, fundraising activities and appeals via
              email.</p>
          </section>

          <section className="p-10 border-b border-mtn-gray-300">
            {Object.keys(preferences?.email.channels.charity ?? {}).length === 0 && <p className="text-sm">
              It looks like you haven't donated to any charities yet.<br />
              If this incorrect and you wish to update your preferences please <Link className="underline" to="/contact">contact us</Link>.
            </p>}

            {Object.keys(preferences?.email.channels.charity ?? {}).map(charityID => getCommunicationCheckbox(charityID, preferences.email.channels?.charity?.[charityID], MarketingTransport.email, user!.email, charityID as MarketingChannel, MarketingSource.charity, preferences?.email.charity_names[charityID]))}
          </section>
        </Card>

      </div>}
    </Page>
  </WithFooter>
}
