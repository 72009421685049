import { type FC } from 'react'
import { Link } from 'react-router-dom'
import { Header, WithFooter } from '~/components'
import { Page } from '@lib/components'

export const TermsAndConditions: FC = () => {
  return <WithFooter>
    <Header />
    <Page skinny className="px-10 my-16 font-medium terms-and-conditions">
      <section>
        <h1 className="my-8 text-4xl font-semibold text-mtn-blue-800">Donor Terms and Conditions</h1>
        <p className="mt-4">These terms and conditions (“Terms”) govern your participation in and use of the service known as MyTenNights, in particular any app, website or web
          pages or other electronic services operated by Shamaazi Limited for the provision of MyTenNights (“the Services”). These Terms set out the terms of your relationship with
          Shamaazi Limited (“we”, “us” or “Shamaazi”). Please read these terms carefully as they affect your rights and obligations under the law.</p>
        <p className="mt-4">If you do not agree to these Terms in full, please do not register with us or use the Services.</p>
        <p className="mt-4">Please also see our <Link className="underline" to="/privacy-policy">Privacy Notice</Link> for information about how we collect and use any data you
          provide whilst using our Services.</p>
      </section>

      <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

      <ol className="list-decimal list-inside">
        <li className="text-2xl text-mtn-blue-800">
          <h2 className="inline my-4">Introduction</h2>
          <ol className="text-base text-mtn-gray">
            <li className="mt-8 ml-8">
              MyTenNights provides a service for donors to split and automate their donations to charities and other organisations across the sacred last ten nights of Ramadan.
            </li>
            <li className="mt-8 ml-8">
              Use of our Services and registration is open to any user over the age of 16 years old.
            </li>
            <li className="mt-8 ml-8">
              These terms will apply to all users, registered users and subscribers (“you” or “users”).
            </li>
            <li className="mt-8 ml-8">
              Different levels of registration or subscription may apply, whichever level of registration or subscription you choose you will be bound by these Terms.
            </li>
            <li className="mt-8 ml-8">
              Please note that these Terms may be amended from time to time. Notification of any changes will be made by posting new terms onto the Services. In continuing to use
              the Services you confirm that you accept the then current Terms in full at the time you use the Services.
            </li>
          </ol>
        </li>

        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

        <li className="text-2xl text-mtn-blue-800">
          <h2 className="inline my-4">Registration, Subscription and Use of the Services</h2>
          <ol className="text-base text-mtn-gray">
            <li className="mt-8 ml-8">
              Use of certain Services may require registration. We may allow access to some Services without registration but we reserve the right to withdraw access without
              registration at any time.
            </li>
            <li className="mt-8 ml-8">
              We are not obliged to permit anyone to register to use the Services and we reserve the right to refuse registration to anyone for any reason.
            </li>
            <li className="mt-8 ml-8">
              We reserve the right to add or remove services and functionality from any registration type or to add or remove registration types.
            </li>
            <li className="mt-8 ml-8">
              We may introduce or offer subscription services, if so then we reserve the right to change the subscription services or subscription periods. When we change our
              subscription types or subscription periods you will be entitled to keep your existing subscription until the end of your then-current subscription period and at the
              end of that period you will be required to subscribe on the new basis.
            </li>
            <li className="mt-8 ml-8">
              If you have subscribed for a subscription service, then your subscription will last for the agreed subscription period. Unless you expressly request otherwise we will
              send you an email towards the end of your subscription inviting you to re-subscribe and unless you opt not to re-subscribe we will be entitled to charge your debit or
              credit card with the subscription fees for the next subscription period.
            </li>
            <p className="mt-4"><u>Donation Services</u></p>
            <li className="mt-8 ml-8">
              We will process your donation via your chosen payment method. We use a third party payment processor in order to facilitate payments to your chosen recipient. If you
              are using the Services to schedule donations to be made on a certain night or day in the future, then we will arrange for the payment or multiple payments to be
              processed on the night or day of your scheduled donation. Your donation will be received by The MyTenNights Fund, a restricted fund operating under our Donor Advised
              Partner (DAF) partner, which is a registered charity in your local region. All tax receipts are issued from The MyTenNights Fund, operating at our DAF partner in your
              country.
            </li>
            <li className="mt-8 ml-8">
              If you have set up an automated donation on any of our services, you can manage and cancel your scheduled donations by logging into your ‘My Profile’ dashboard.
            </li>
            <li className="mt-8 ml-8">
              You may decide to pay an optional tip to us when making your donation. If you do so we will collect and retain such amount. In providing the Services we may also
              receive commissions and other fees from the charities or other organisations who we partner with. For more information regarding our charges please click on the
              following link: <Link className="underline" to="/faq">FAQs</Link>
            </li>
            <li className="mt-8 ml-8">
              If you are asked to confirm if you want us to submit a claim for Gift Aid on behalf of your chosen recipient charity or other organisation, you are solely responsible
              for confirming that you and/or your donation meet the criteria to submit such a claim for Gift Aid and you agree that you will complete any required declarations
              truthfully and fully.
            </li>
            <li className="mt-8 ml-8">
              We do not covenant, represent or warrant that any money you donate will be used for any particular appeal, cause or purpose. We are not responsible or liable for the
              organisation who receives your donations and while we undertake due diligence in relation to the charities and organisations we list we do not guarantee or warrant
              that their charitable purposes and conduct will be suitable for you and we recommend that you consider the relevant charities and organisations carefully before
              setting up a donation.
            </li>
            <li className="mt-8 ml-8">
              The charity or organisation to which you donate is responsible and in control of how your donation is used and they have sole discretion as to how they use the funds
              donated. Any concerns or issues you may have with how such donations may have been used or not used by the organisation you have donated to must only be raised
              directly with the recipient charity. Where we consider a charity it appropriate, we may release your donation to your charity in tranches, so as to ensure each
              tranche is used as intended, and release the funding as agreed milestones are achieved. Where we deem a charity has been fraudulent, or has been unable to produce
              feedback on how your donations have been used, you agree that we may withhold any further tranches of your donation from that charity, and we may direct it to a
              charity that is in the same area of interest, yet has a better track record of transparency and impact. If your funds are redirected, we will attempt to inform you
              via email.
            </li>
            <li className="mt-8 ml-8">
              Once you have donated and the payment has been processed any refund will be at the discretion of the recipient charity or other organisation but you should assume
              that refunds will not be granted.
            </li>
          </ol>
        </li>

        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

        <li className="text-2xl text-mtn-blue-800">
          <h2 className="inline my-4">Data Protection</h2>
          <ol className="text-base text-mtn-gray">
            <li className="mt-8 ml-8">
              When you register with the Services we will ask for some of your personal information. Any personal information you provide us with will be handled in accordance with
              our Privacy Notice which can be seen here: <Link className="underline" to="/privacy-policy">Privacy Notice</Link>.
            </li>
            <li className="mt-8 ml-8">
              You agree that personal information supplied to us will be kept up to date and that we may use the information provided to us to contact you. You are required to
              provide us with such information as we may request which may include your name, email address, telephone number, country of residence and such other information as we
              may reasonably request.
            </li>
            <li className="mt-8 ml-8">
              If we have reason to believe that there is likely to be a breach of security or misuse of the Services through your registration, subscription or otherwise through
              the use of your password, we may notify you and require you to change your password with us, or we may suspend your use of the Services until you have done so. Until
              you have changed all your passwords or we have re-authorised your use, you will not be able to access the Services.
            </li>
            <li className="mt-8 ml-8">
              You are not entitled to allow any other person to use or borrow your log in details or password, each additional user must use and register for the Services
              separately.
            </li>

          </ol>
        </li>

        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

        <li className="text-2xl text-mtn-blue-800">
          <h2 className="inline my-4">Abuse of the Services</h2>
          <ol className="text-base text-mtn-gray">
            <li className="mt-8 ml-8">
              Please note that the Services are strictly for the purposes of making charitable donations to third party charities and organisations. We do not recommend which
              charity or organisations you should donate to or advise you how to adhere to your religious beliefs or customs.
            </li>
            <li className="mt-8 ml-8">
              Any content posted or published by you using the Services will be your personal responsibility. You will be personally liable for claims relating to negligence,
              defamation, breach of intellectual property, privacy or any other claim arising from content you post.
            </li>
            <li className="mt-8 ml-8">
              You may not publish any illegal, offensive, inaccurate, misleading, defamatory or fraudulent content. If any such content is published by you or if in our opinion any
              content is published by you which damages our goodwill or the goodwill attaching to the Services we reserve the right at our discretion and without notice to take
              such action as we deem necessary including removing the content from the Services and terminating your registration. You agree that if you share any content from our
              Services externally (including on any social media platforms), that you will not spam our content, or post our content on an inappropriate forum. You will not publish
              any illegal, offensive, inaccurate, misleading, defamatory or fraudulent information about our Services, including when sharing content from our Services.
            </li>
            <li className="mt-8 ml-8">
              You agree that you will not:
              <p className="mt-4">· solicit log-in information or access an account belonging to someone else;</p>
              <p className="mt-4">· bully, intimidate, or harass any user of the Services;</p>
              <p className="mt-4">· do anything illegal, unlawful, misleading, malicious, or discriminatory using the Services;</p>
              <p className="mt-4">· do anything to suggest, express or imply that statements made by you are endorsed by us;</p>
              <p className="mt-4">· impersonate any other person whether or not that other person is a user of the Service.</p>
            </li>
            <li className="mt-8 ml-8">
              We also reserve the right at our discretion to remove any content from the Services, terminate your registration and restrict your access to our services at any time
              for any reason.
            </li>
            <li className="mt-8 ml-8">
              In the event that you are informed that you will no longer be entitled to access the services you will not be entitled to register again and you will no longer have
              permission to use the Services.
            </li>
            <li className="mt-8 ml-8">
              If you come across any offensive, inaccurate or damaging material on the Services or if you are subject to any form of abuse or harassment we ask that you contact us
              immediately.
            </li>
            <li className="mt-8 ml-8">
              You agree not to upload any files or post or publish anything on the Services that contains viruses, corrupted files, or malicious code or any other similar software
              or programs that may damage the operation of another's device.
            </li>
          </ol>
        </li>

        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

        <li className="text-2xl text-mtn-blue-800">
          <h2 className="inline my-4">Advertising and Commercial Use</h2>
          <ol className="text-base text-mtn-gray">
            <li className="mt-8 ml-8">
              Users of the Services are not entitled to directly advertise to or solicit the custom of other users without our express written consent.
            </li>
            <li className="mt-8 ml-8">
              You are not entitled to resell or commercially exploit the contents of the Services. You are not entitled to use any data mining, robots, or similar data gathering
              and extraction tools to collect user names, e-mail addresses or any other data for the purposes of sending unsolicited e-mail or for any other use.
            </li>
          </ol>
        </li>

        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

        <li className="text-2xl text-mtn-blue-800">
          <h2 className="inline my-4">Our Liability</h2>
          <ol className="text-base text-mtn-gray">
            <li className="mt-8 ml-8">
              We will operate the Services with the reasonable skill and care of an online service provider. Services provided do not extend to detailed monitoring or supervision
              of content, comments or communications between users of the Services.
            </li>
            <li className="mt-8 ml-8">
              From time to time it may be necessary to suspend access to the Services for a period of time and any such interruptions shall not constitute a breach by us of these
              Terms.
            </li>
            <li className="mt-8 ml-8">
              We will not be liable for any a) business loss; b) financial loss; c) economic loss; d) consequential loss; e) loss of data; f) lost profit; f) loss of bargain or
              opportunity or f) indirect loss arising as a result of your use of the Services even if such loss is incurred or suffered as a result of our negligence or otherwise.
            </li>
            <li className="mt-8 ml-8">
              We will not be liable for any use of the Services other than for the purposes stated in these Terms.
            </li>
            <li className="mt-8 ml-8">
              We will not be liable for any breach of the Data Protection Act 2018, the General Data Protection Regulation or any other applicable legislation relating to the
              protection of personal data in the event that you disclose personally identifiable information relating to any third party.
            </li>
            <li className="mt-8 ml-8">
              We will not be liable if content you have posted and stored on the Services is lost, corrupted or damaged.
            </li>
            <li className="mt-8 ml-8">
              We are not responsible for ensuring you comply with your religious customs or beliefs. We cannot guarantee that through using the Services you will fulfil your
              religious obligations, and, in particular we will not be responsible if a donation cannot be made due to the cancellation or expiry of any debit or credit card
              provided to our payment processing partners or as a result of any failure on your part to comply with these terms.
            </li>
            <li className="mt-8 ml-8">
              Our total liability to you arising under or in connection with these Terms, whether in contract, tort (including negligence), breach of statutory duty or otherwise,
              shall never exceed the total sum of the donations you have made using the Services in the preceding period of 12 months, such period ending on the date the
              circumstances giving rise to our purported liability arose.
            </li>
            <li className="mt-8 ml-8">
              Nothing in these Terms will limit our liability for fraud or for death or personal injury caused as a result of our negligence.
            </li>
            <li className="mt-8 ml-8">
              We may select partners to display or promote to you when you use the Services. We are not responsible for any of the information or services offered by such partners.
              If you choose to follow a link to another website which is displayed to you when using the Services, you do so at your own risk.
            </li>

          </ol>
        </li>

        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

        <li className="text-2xl text-mtn-blue-800">
          <h2 className="inline my-4">Cancellation and Termination</h2>
          <ol className="text-base text-mtn-gray">
            <li className="mt-8 ml-8">
              If you wish to cancel your registration, please notify us and we will delete your registration. Posted content will remain available on our Services following
              termination of your registration.
            </li>
          </ol>
        </li>

        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

        <li className="text-2xl text-mtn-blue-800">
          <h2 className="inline my-4">Intellectual Property</h2>
          <ol className="text-base text-mtn-gray">
            <li className="mt-8 ml-8">
              The format and content of the Services (and any downloadable app or materials offered by us) is protected by copyright and we reserve all rights in relation to our
              copyright whether owned or licensed to us and all rights are reserved to any of our registered and unregistered trademarks (whether owned or licensed to us) which
              appear on the Services.
            </li>
            <li className="mt-8 ml-8">
              The Services may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without our express written consent.
              You may not systematically extract and/or re-utilise parts of the contents of the Services without our express written consent.
            </li>
            <li className="mt-8 ml-8">
              If you have downloaded a copy of our website or any application or software provided by us, then subject to your agreement to these Terms we hereby grant a
              non-exclusive, non-transferable licence to download a copy of such materials to such devices as are personally used by you and to use such materials for your personal
              purposes. You may also take back-up copies of the App for personal use. We reserve the right to terminate this licence if you are in breach of these Terms. You may
              not transfer this licence.
            </li>
            <li className="mt-8 ml-8">
              You may not use, copy, modify or transfer any software, materials or documents we have supplied to you except as expressly set out in these Terms. You may not
              translate, reverse engineer, decompile, disassemble, modify or create derivative works based on the software, materials or documents, except as expressly permitted by
              law.
            </li>
            <li className="mt-8 ml-8">
              By providing content for distribution by our Services you expressly grant us a royalty-free, perpetual, irrevocable licence to use such content in order to deliver
              the Services to you.
            </li>

          </ol>
        </li>

        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

        <li className="text-2xl text-mtn-blue-800">
          <h2 className="inline my-4">Notice and Take-Down</h2>
          <ol className="text-base text-mtn-gray">
            <li className="mt-8 ml-8">
              We will make all reasonable efforts to delete accounts which are being used in breach of our Terms and to identify and remove content that is defamatory or infringing
              on intellectual property rights or otherwise in breach of our media policies when we are notified but we cannot be responsible if you have failed to provide the
              relevant information.
            </li>
            <li className="mt-8 ml-8">
              In the event that you believe that an account is being used in an inappropriate manner or that any content which is distributed using the Services is defamatory or
              infringing on intellectual property rights you should notify us in writing either by email to <a className="underline text-mtn-blue"
                                                                                                               href="mailto:contact@mytennights.com">contact@mytennights.com</a> including
              the following:
              <p className="mt-4">· Your full name and contact details, including postal address, telephone number and e-mail address;</p>
              <p className="mt-4">· The location where the defamatory or infringing content appears;</p>
              <p className="mt-4">· The content that you believe is defamatory or infringing on intellectual property rights;</p>
              <p className="mt-4">· The reasons that you believe the content is defamatory or infringing on intellectual property rights;</p>
              <p className="mt-4">· A statement confirming that you are authorised to act on behalf of the claimant or rights holders; and</p>
              <p className="mt-4">· A signed declaration truth in respect of the information in the notice.</p>
            </li>
            <li className="mt-8 ml-8">
              Any statement made under this clause may be used in court proceedings.
            </li>
          </ol>
        </li>

        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

        <li className="text-2xl text-mtn-blue-800">
          <h2 className="inline my-4">General</h2>
          <ol className="text-base text-mtn-gray">
            <li className="mt-8 ml-8">
              You shall comply with all foreign and local laws and regulations which apply to your use of our Services in whatever country you are physically located.
            </li>
            <li className="mt-8 ml-8">
              These conditions are governed by and construed in accordance with the laws of England and Wales. You agree, as we do, to submit to the non-exclusive jurisdiction of
              the English courts.
            </li>
            <li className="mt-8 ml-8">
              If you breach these Terms and we decide to take no action or neglect to do so, then we will still be entitled to take action and enforce our rights and remedies for
              any other breach.
            </li>
            <li className="mt-8 ml-8">
              We will not be responsible for any breach of these Terms caused by circumstances beyond our reasonable control.
            </li>
            <li className="mt-8 ml-8">
              We may make changes to the format of the Services or its content at any time without notice.
            </li>
          </ol>
        </li>

        <div className="flex-none bg-gradient-to-r from-white via-mtn-gray-300 to-white my-8 h-0.5"></div>

        <li className="text-2xl text-mtn-blue-800">
          <h2 className="inline my-4">Contact Us</h2>
          <ol className="text-base text-mtn-gray">
            <li className="mt-8 ml-8">
              If you have any questions about these Terms, please contact us at <a className="underline text-mtn-blue"
                                                                                   href="mailto:contact@mytennights.com">contact@mytennights.com</a>
            </li>
          </ol>
        </li>

      </ol>
    </Page>
  </WithFooter>
}
