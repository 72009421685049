import { type FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { Choice, Country, displayCurrency, getCurrencySymbol, isMultiCharity, StringUtil } from '@lib/services'
import { useLocalFormPersist } from '@lib/hooks'
import { Button, InputWithRef, SelectWithRef } from '@lib/components'

import { CheckoutPage, CheckoutProgress, CheckoutSummaryCard, Pages, WithFooter } from '~/components'
import {
  ActiveCampaign,
  type AddressLookupStore,
  calculateTip,
  fixTipValue,
  getCausesTotal,
  getTipAmount,
  PaymentDetails,
  type PaymentFormData,
  PaymentType,
  type ScheduleResponse,
  Tip,
  useAuthWithSignup,
  useCheckoutPlan,
  useSchedulePayment,
  Variant
  , MTNtracking,
  MessageBox,
  ActiveExperiments,
  TestVersion
} from '@shamaazi/mytennights'
import { useSharePageDetails } from '~/hooks/useSharePageDetails'
import { useHiddenZendeskWidget } from '~/hooks/useHiddenZendeskWidget'
import { MytennightsTestId, PaymentFormTestId } from '@lib/testing'

import { getTotalFees } from '~/service/fees'
import { Header } from '~/components/header'
import { Experiment, Variant as SplitTestVariant } from '@lib/react-ab-test'

export const Payment: FC<{ nextPage: string, addressLookupStore: AddressLookupStore }> = ({
  nextPage,
  addressLookupStore
}) => {
  const history = useHistory()
  const { plan, resetPlan } = useCheckoutPlan()
  const { setSharePageDetails } = useSharePageDetails()
  const { user } = useAuthWithSignup()

  useHiddenZendeskWidget()
  const causesTotal = getCausesTotal(plan)
  const form = useForm<PaymentFormData>({
    defaultValues: {
      campaign: ActiveCampaign.myTenNights,
      addressLine1: '',
      country: plan.charity.country,
      email: plan.donorDetails.email ?? user?.email ?? '',
      phone: '',
      countryCode: '',
      firstName: '',
      giftAid: plan.paymentDetails.giftAid,
      taxReceipt: false,
      lastName: '',
      postalCode: '',
      tipPercent: causesTotal > 200000 ? Tip.FivePercent : Tip.TenPercent, // if amount more than 200, 5% tip by default, else 10%
      emailOptIn: plan.emailOptIn,
      charityEmailOptIn: plan.charityEmailOptIn,
      paymentType: PaymentType.applePay,
      ...MTNtracking.getPartner()
    }
  })

  const resetFormStorage = useLocalFormPersist('checkout_payment', form.watch, form.setValue)
  if (form.getValues('campaign') !== ActiveCampaign.myTenNights) {
    form.reset()
    resetFormStorage()
  }
  const { register, handleSubmit, formState: { errors }, watch, setValue } = form

  const [tipPercent, tipValue] = watch(['tipPercent', 'tipValue'])

  const onSuccessfulPayment = (scheduleResponse: ScheduleResponse, formData: PaymentFormData): void => {
    MTNtracking.paymentSuccessful(formData.email, Boolean(user), plan, scheduleResponse, tipAmount, totalFees, formData, scheduleResponse.shareCode)
    queryClient.invalidateQueries('schedules').catch(() => {
    })
    const totalNightValue = MTNtracking.getNightsLengthChoice(plan?.totalNight.value)
    const splitChoiceValue = plan?.split
    setSharePageDetails({
      email: scheduleResponse.email,
      shareCode: scheduleResponse.shareCode,
      matchedAmount: scheduleResponse.matchedAmount,
      total: scheduleResponse.total,
      matchPotBalance: scheduleResponse.matchPotBalance,
      currency: plan.charity.currency,
      firstName: formData.firstName,
      lengthChoice: totalNightValue,
      splitChoice: splitChoiceValue,
      giftAid: plan.paymentDetails.giftAid,
      fridayGiver: plan.fridayGivingOptIn === Choice.yes,
      fridayGiverAmount: plan.fridayGiving,
      wakf: plan.wakfOptIn === Choice.yes,
      wakfAmount: plan.wakf
    })
    resetPlan()
    form.reset()
    resetFormStorage()
    history.push(nextPage)
  }

  const { paymentRequest: phonePaymentRequest, submit, isLoading, error: paymentError } = useSchedulePayment(onSuccessfulPayment, form.getValues())
  const payByPhone = phonePaymentRequest && form.getValues('paymentType') !== PaymentType.card

  const tipAmount = getTipAmount(plan.causes, tipPercent, fixTipValue(tipValue))
  const totalFees = getTotalFees(plan, tipAmount)

  const total = causesTotal + totalFees + tipAmount

  useEffect(() => {
    if ((user?.email ?? '') !== '') {
      setValue('email', user?.email ?? '')
    }
    if (tipPercent === Tip.Other) {
      setValue('tipValue', calculateTip(plan.causes, 3) / 100)
    }
  }, [user, setValue, tipPercent, plan.causes])

  useEffect(() => {
    if (phonePaymentRequest !== null && !StringUtil.isEmpty(plan.charity?.charity_id)) {
      phonePaymentRequest.update({
        currency: plan?.charity?.currency,
        total: {
          label: 'MyTenNights total amount',
          amount: total
        }
      })
    }
  }, [total, phonePaymentRequest, plan?.charity?.currency, plan?.charity?.charity_id])

  const queryClient = useQueryClient()
  const tipTitle: string[] = [
    'Help Us Stay Free!',
    '100% Donation Policy!',
    'Your MyTenNights tip',
    'Send 100% of my donation to charity'
  ]

  return <WithFooter navigation={false}>
    <Header />
    <CheckoutProgress step={Pages.Payment} />
    <h1 className="text-xl text-center lg:text-4xl text-mtn-blue-900 font-bold">Payment</h1>
    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
    <form onSubmit={handleSubmit((data) => submit(data))}>
      <CheckoutPage>
        <div className="font-medium lg:col-start-1 lg:col-end-7 xl:col-end-8">
          <section className="p-6 flex md:mb-10 rounded-xl bg-mtn-gray-payment">
            <div className='flex flex-col lg:flex-row justify-between w-full'>
              <div className='flex flex-col md:mb-0 lg:w-1/2'>
                <div className='flex lg:justify-start justify-between relative'>
                  <Experiment name={ActiveExperiments.MTNTipTitle}>
                    <SplitTestVariant name={TestVersion.control}>
                      <p className="text-mtn-blue-800 text-lg font-bold">{tipTitle[0]}</p>
                    </SplitTestVariant>
                    <SplitTestVariant name={TestVersion.variantA}>
                      <p className="text-mtn-blue-800 text-lg font-bold">{tipTitle[1]}</p>
                    </SplitTestVariant>
                    <SplitTestVariant name={TestVersion.variantB}>
                      <p className="text-mtn-blue-800 text-lg font-bold">{tipTitle[2]}</p>
                    </SplitTestVariant>
                    <SplitTestVariant name={TestVersion.variantC}>
                      <p className="text-mtn-blue-800 text-lg font-bold">{tipTitle[3]}</p>
                    </SplitTestVariant>
                  </Experiment>

                  <MessageBox>
                    <span> 100% of your donation is passed on to {isMultiCharity(plan.charity.charity_id) ? <>charity</> : <> <span className='font-bold'>{plan.charity.charity_name}</span> {plan.charity.registration_number !== '' && `- registered charity number ${plan.charity.registration_number}`}.</>}
                    </span>
                    <br /><br />
                    {plan.fridayGivingOptIn === Choice.yes && <><span> We will copy the percentage you select here for your Friday Givers donation tip; this means your weekly donation will be debited with the same percentage tip as above to <span className='font-bold'>help us stay free for your charity.</span> For example, if you choose to generously give us a 5% tip for your MyTenNights donation, we will also debit a 5% tip for your weekly Friday Givers donation.</span>
                      <br /><br /> </>}
                    {plan.charity.country === Country.GB && <span>Your tip is gift aid eligible and is received as a donation to our charitable entity: The Shamaazi Foundation, a restricted fund operating at Mercy Mission UK, registered charitable organisation number 1122922. The gift aid from your tip donation will also be passed on to the fund.</span>}</MessageBox>
                </div>
                <p className="mt-1">Your tip helps us maintain a <span className=' font-bold'>100% donation policy.</span></p>
              </div>
              <div className='flex flex-col mt-5 lg:mt-0 sm:mt-0 md:mt-5'>
                <div className="items-center justify-between md:flex">
                  <SelectWithRef
                    data-test-id={PaymentFormTestId.tipDropdown}
                    aria-label="Tip percent"
                    className="w-full pr-12 font-medium rounded-lg bg-white"
                    variant="mtn"
                    {...register('tipPercent', { required: true })}>
                    <option value={Tip.FivePercent}>{displayCurrency({
                      amount: calculateTip(plan.causes, 5),
                      currency: plan.charity.currency
                    })} (5%)
                    </option>
                    <option value={Tip.TenPercent}>{displayCurrency({
                      amount: calculateTip(plan.causes, 10),
                      currency: plan.charity.currency
                    })} (10%)
                    </option>
                    <option value={Tip.FifteenPercent}>{displayCurrency({
                      amount: calculateTip(plan.causes, 15),
                      currency: plan.charity.currency
                    })} (15%)
                    </option>
                    <option value={Tip.Other}>Other</option>
                  </SelectWithRef>
                </div>
                <div className={`justify-end mt-4 ${tipPercent === Tip.Other ? '' : 'hidden'}`}>
                  <div className="relative">
                    <span className="absolute transform -translate-y-1/2 pointer-events-none left-4 top-2/4">
                      {getCurrencySymbol(plan.charity.currency)}
                    </span>
                    <InputWithRef
                      data-test-id={PaymentFormTestId.tipAmount}
                      variant="mtn"
                      className="text-right w-44 rounded-xl bg-white"
                      aria-label="Tip amount"
                      placeholder="0.00"
                      step="0.01"
                      value={tipValue && Math.max(0, tipValue)}
                      type="number"
                      invalid={errors.tipValue !== undefined}
                      {...register('tipValue', {
                        validate: {
                          max: (v) => (fixTipValue(v) <= 999999) || tipPercent !== Tip.Other || 'Sorry, we don\'t accept tips this large!',
                          min: (v) => (fixTipValue(v) >= 0) || tipPercent !== Tip.Other || 'Sorry, the minimun tip is \u00A30.00'
                        },
                        setValueAs: (v) => fixTipValue(parseFloat(v))
                      })}
                      inputMode="decimal"
                      size={1} />
                  </div>
                </div>
                {tipPercent === Tip.Other && <div
                  className="mt-2 text-xs text-right text-mtn-red min-h-xs">{errors?.tipValue?.message ?? ''}</div>}
              </div>
            </div>
          </section>
          <PaymentDetails form={form} addressLookupStore={addressLookupStore} variant={Variant.mtn} tracking={MTNtracking} />
        </div>
        <div className="lg:col-start-8 xl:col-start-9 lg:col-end-12">
          <CheckoutSummaryCard
            tip={tipAmount}
            fees={totalFees}
            showTip>
            <div className="flex items-center justify-between w-9/12">
              {
                !payByPhone && <Button
                  className="w-full"
                  data-test-id={MytennightsTestId.submitButton}
                  variant="mtn-primary"
                  disabled={isLoading}
                  loading={isLoading}>
                  SUBMIT
                </Button>
              }
            </div>
            {paymentError && <div data-test-id={PaymentFormTestId.errorText}
              className="mt-3 p-3 text-sm text-right text-mtn-red">
              {paymentError instanceof Error ? paymentError.message : 'Sorry, we weren\'t able to create your donation schedule'}
            </div>}
            <p className="mt-3 text-xs mx-5">
              By continuing, you agree with the MyTenNights <Link className="underline text-mtn-blue-500"
                to="/terms-and-conditions">terms</Link> and <Link
                  className="underline text-mtn-blue-500" to="/privacy-policy">privacy policy</Link>.
              You also agree with {plan.charity.charity_name}&apos;s <a
                className="underline text-mtn-blue-500" href={plan.charity.privacy_policy_url}
                target="_blank" rel="noreferrer noopener">privacy policy</a>.
            </p>
            <p className="mt-3 text-xs mx-5">
              You also authorise MyTenNights to send instructions to the financial institution that issued
              your card to take payments from your card account in
              accordance with the agreement with you.
            </p>
          </CheckoutSummaryCard>
        </div>
      </CheckoutPage>
    </form>
  </WithFooter>
}
